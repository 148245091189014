import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function LineGraph({
  min,
  middle,
  middle2,
  max,
  graph,
  name,
  isMale,
}) {
  const [grapPos, setGraphPos] = useState(0);
  const [dontUse, setDontUse] = useState(false);
  const [pointerLocation, setPointerLocatiion] = useState(0);

  useEffect(() => {
    let poss = calculatePercentage(graph, min, max);
    setPointerLocatiion(0);
    if (poss < 0) {
      poss = 0;
      setPointerLocatiion(1);
    }
    if (poss > 100) {
      poss = 85;
      setDontUse(true);
      setPointerLocatiion(2);
    }
    setGraphPos(poss);
  }, [graph, min, max]);
  const calculatePercentage = (graphValue, minValue, maxValue) => {
    return ((graphValue - minValue) / (maxValue - minValue)) * 100;
  };
  return (
    <div className="w-full text-white">
      <div>
        <div
          className="w-16 relative "
          style={{ left: `${grapPos}%`, transition: "left 1s ease-in-out" }}
        >
          <div className={`${dontUse ? "" : "relative right-8 "}`}>
            <div className=" w-full flex flex-col items-center justify-center">
              <div className=" flex flex-col items-center space-x-2">
                <h2 className=" line-clamp-1 overflow-hidden">{name}</h2>
                {isMale ? (
                  <img
                    alt="lisa"
                    src="/assets/milestone/g-male.png"
                    className="w-8 h-8 bg-white rounded-full"
                  />
                ) : (
                  <img
                    alt="lisa"
                    src="/assets/milestone/g-female.png"
                    className="w-8 h-8 bg-white rounded-full"
                  />
                )}
              </div>
              <h2>{graph}</h2>
            </div>
            <div
              className={`w-full flex ${
                pointerLocation == 0
                  ? "items-center justify-center"
                  : pointerLocation == 1
                  ? "items-start"
                  : pointerLocation == 2
                  ? "justify-end"
                  : ""
              }`}
            >
              <div className="w-1 h-2 bg-white"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  justify-between w-full">
        <div className="w-full">
          <div className="rounded-s-full bg-red-500 w-full h-4"></div>
          <div className="w-full flex items-center justify-between">
            <div className="w-1 h-2 bg-white"></div>
            <div className="w-1 h-2 bg-white"></div>
          </div>
          <div className=" w-full flex items-center justify-between">
            <h2>{min}</h2>
            <h2>{middle}</h2>
          </div>
        </div>
        <div className="w-full">
          <div className=" bg-green-500 w-full h-4"></div>
          <div></div>
        </div>
        <div className="w-full">
          <div className=" rounded-e-full bg-red-500 w-full h-4"></div>
          <div className="w-full flex items-center justify-between">
            <div className="w-1 h-2 bg-white"></div>
            <div className="w-1 h-2 bg-white"></div>
          </div>
          <div className=" w-full flex items-center justify-between">
            <h2>{middle2}</h2>
            <h2>{max}</h2>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-2">
        <img
          alt="lisa"
          src="/assets/milestone/g-female.png"
          className="w-8 h-8 bg-white rounded-full"
        />
        <div>WHO</div>
      </div>
    </div>
  );
}
