import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InitAudioCall } from "../utils/Api";
import { BiHomeAlt } from "react-icons/bi";
import moment from "moment/moment";

export default function Confirmed() {
  const Data = useSelector((state) => state.Temp);
  const Const = useSelector((state) => state.Const);
  const [headline, setHeadline] = useState(
    "Your consultation with our doctor is confirmed!"
  );
  const [date, setdate] = useState("");
  const [time, setTime] = useState("");
  const [isSubHeading, setSubHeading] = useState(false);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (Data.guestId != null) {
      if (Data.consultType == "audio") {
        let data = {
          userType: Data.userType,
          caller: Data.number,
          agent_number: "+919810530054",
          action: "CONSULTATION",
        };
        setHeadline(
          "Your Consultation with our doctor is confirmed. Our doctor is calling now."
        );
        // InitAudioCall(data).then(res=>{
        //   if(res.status==true){

        //   }
        // })
      } else {
        setTimeout(() => {
          navigate("/doctor-oncall");
        }, 1500);
      }
    }
    if (Const.consultType != null) {
      setdate(Const.finalDate);
      setTime(Const.slotToShow);
      setSubHeading(true);
    }
  }, []);
  const handleHomeClick = () => {
    var url = "https://mygleeo.com/services/child-care.html";
    window.open(url);
    try {
      window.Android.showToast(
        JSON.stringify({
          type: "HOME_WINDOW_OPEN",
          isClosed: false,
          url,
        })
      );
    } catch (error) {}
  };
  return (
    <>
      <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
        <div className="flex flex-col items-center justify-center">
          <img src="./assets/confirmed.svg" />
          <h1 className="text-2xl text-primary mt-4 text-center">{headline}</h1>
          <h1
            className="text-lg text-primary mt-4 "
            style={{ display: isSubHeading == true ? "flex" : "none" }}
          >
            Your Consultation with our doctor for{" "}
            {moment(date).format("MMMM Do YYYY")} between {time} is confirmed.
            We will remind you 10 mins before your appointment time
          </h1>
          <div
            onClick={handleHomeClick}
            className=" w-16 h-16 rounded-full bg-primary flex items-center justify-center mt-10 cursor-pointer p-4"
          >
            <BiHomeAlt className="w-10 h-10 text-white" />
          </div>
        </div>
      </div>
    </>
  );
}
