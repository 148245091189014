const handleLoading = (state = { loading: false, otp: false }, action) => {
  switch (action.type) {
    case "setLoading":
      return (state=action.data);
    default:
      return state;
  }
};

export default handleLoading;
