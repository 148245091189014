import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDTsPDscc7-SDCB5Kx15lsuEx0aBmFcchA",
  authDomain: "gleeohealth-38ab6.firebaseapp.com",
  projectId: "gleeohealth-38ab6",
  storageBucket: "gleeohealth-38ab6.appspot.com",
  messagingSenderId: "150308866330",
  appId: "1:150308866330:web:663457bc488b74728c04a3",
  measurementId: "G-BD1EEMYYFD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);