export const setTheme = (data) => {
  return {
    type: "setTheme",
    data,
  };
};

export const setLoading = (data) => {
  return {
    type: "setLoading",
    data,
  };
};
export const setOtpVerifiedLaoding = (data) => {
  return {
    type: "setLoadingOtp",
    data,
  };
};
export const setUserData = (data) => {
  return {
    type: "SET_USER",
    data,
  };
};
export const setBasicData = (data) => {
  return {
    type: "SET_BASIC",
    data,
  };
};
export const setOtp = (data) => {
  return {
    type: "SET_OTP",
    data,
  };
};
export const setMainUser = (data) => {
  return {
    type: "SET_MAINUSER",
    data,
  };
};
export const setVideoCallData = (data) => {
  return {
    type: "SET_VIDEO",
    data,
  };
};
export const setTempData = (data) => {
  return {
    type: "SET_TEMP",
    data,
  };
};
export const setConsultData = (data) => {
  return {
    type: "SET_CONSTSAVER",
    data,
  };
};
export const setMileStone = (data) => {
  return {
    type: "SET_MILESTONE",
    data,
  };
};

