import { useEffect, useState, useLayoutEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetVideoLog, InitAudioCall } from "../utils/Api";
import { setVideoCallData } from "../Redux/Actions/Actions";

export default function OnCall() {
  const Data = useSelector((state) => state.Temp);
  const Basic = useSelector((state) => state.Basic);
  const Dispatch =  useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(600);
  const [timerLeft, setTimer] = useState(20);
  const [headline, setHeadline] = useState(
    "Your consultation call will start in"
  );
  const [para, setPara] = useState(
    "Ensure that you are in a zone with good network and connectivity"
  );
  const [img, setImg] = useState("./assets/consultation.svg");


  useEffect(()=>{
    if(Data.consultType=="audio"){
      let data ={
        userType:Data.userType,
        caller:Data.number,
        agent_number:"+919810530054",
        action:"CONSULTATION"
      }
      InitAudioCall(data).then(res=>{
        if(res.status==true){

        }
      })
    }
  },[])
  useEffect(() => {
    if (timeLeft === 0) {

      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    if (timerLeft === 0) {
      handleCallFinihed();
      return;
    }

    const interval = setInterval(() => {
      handleCountDawnEfect();
      setTimer((prevTime) => prevTime - 1);
    }, 30000);

    return () => clearInterval(interval);
  }, [timerLeft]);

  const handleCountDawnEfect=()=>{
//      res
//     "data": {
//       "ID": 328,
//       "callerId": 397,
//       "calleeId": 404,
//       "branchId": 16,
//       "roomName": "16_397_404_16585659",
//       "roomUrl": "https://meet.jit.si/16_397_404_16585659",
//       "status": 0,
//       "alertId": 2567,
//       "startTime": "2023-08-24T12:48:08.983",
//       "endTime": null,
//       "recType": "Intervention"
//     }

    if(Data.consultType!="audio"){
      GetVideoLog(Data.guestId,Data.userType).then(res=>{
        if(res.data!=null){
          let data={
            roomName:res.data.roomName,
            name:Basic.name
          }
          Dispatch(setVideoCallData(data))
          navigate("/videocall")
        }
      })
    }
  }
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}m ${seconds
      .toString()
      .padStart(2, "0")}s`;
  };

  const handleCallFinihed = () => {
    setHeadline("Your call with our docter has ended!");
    setPara("Don't go anywhere - our doctor is preparing your prescription!");
    setImg("./assets/hourglasscon.svg");
  };

  return (
    <>
      <div className="container h-screen max-w-md mx-auto p-8 bg-primary text-white">
        <div className="flex flex-col items-center justify-center">
          <div className=" rounded-full bg-gray-100 w-80 h-80 flex items-center justify-center bg-opacity-30">
            <div className=" rounded-full bg-gray-200 w-72 h-72 flex items-center justify-center bg-opacity-30">
              <div className=" rounded-full bg-gray-300 w-64 h-64 flex items-center justify-center bg-opacity-30">
                <div className=" rounded-full bg-gray-400 w-60 h-60 flex items-center justify-center bg-opacity-30 p-8">
                  <img src={img} className="" />
                </div>
              </div>
            </div>
          </div>

          <h1 className="text-2xl font-bold mt-4 text-center">{headline}</h1>
          <div className="bg-white rounded-lg px-8 py-2 mt-4">
            <h1 className="text-primary">{formatTime(timeLeft)}</h1>
          </div>
          <h1 className="text-lg mt-4 ">{para}</h1>
        </div>
      </div>
    </>
  );
}
