import React from "react";
import { useLayoutEffect } from "react";
import { GetDietPlan, normalHeader } from "../../../utils/Api";
import { useState } from "react";
import moment from "moment";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function Diet() {
  const queryParameters = new URLSearchParams(window.location.search);
  const UserType = queryParameters.get("UserType");
  const AuthToken = queryParameters.get("AuthToken").replace(" ", "+");
  const UserId = parseInt(queryParameters.get("UserId"));

  const [data, setData] = useState([]);

  useLayoutEffect(() => {
    let header = {
      ...normalHeader,
      AuthToken,
      userId: UserId,
    };
    GetDietPlan(UserId, UserType, header).then((res) => {
      console.log(res);
      if (res.status) {
        let data = res.data;
        let temp = new Array();
        data.forEach((Element) => {
          let DO6AM = Element.diets.filter((d) => d.time == "6:00");
          let DO9AM = Element.diets.filter((d) => d.time == "9:00");
          let DO12PM = Element.diets.filter((d) => d.time == "12:00");
          let DO1PM = Element.diets.filter((d) => d.time == "13:00");
          let DO3PM = Element.diets.filter((d) => d.time == "15:00");
          let DO6PM = Element.diets.filter((d) => d.time == "18:00");
          let DO9PM = Element.diets.filter((d) => d.time == "21:00");
          let DO11PM = Element.diets.filter((d) => d.time == "23:00");

          let temp2 = new Array();
          if (DO6AM.length > 0) {
            temp2.push(DO6AM);
          }
          if (DO9AM.length > 0) {
            temp2.push(DO9AM);
          }
          if (DO12PM.length > 0) {
            temp2.push(DO12PM);
          }
          if (DO1PM.length > 0) {
            temp2.push(DO1PM);
          }
          if (DO3PM.length > 0) {
            temp2.push(DO3PM);
          }
          if (DO6PM.length > 0) {
            temp2.push(DO6PM);
          }
          if (DO9PM.length > 0) {
            temp2.push(DO9PM);
          }
          if (DO11PM.length > 0) {
            temp2.push(DO11PM);
          }

          let data = {
            rxDate: Element.rxDate,
            diets: temp2,
          };
          temp.push(data);
        });
        setData(temp);
      }
    });
  }, []);

  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light overflow-scroll  px-4 py-2">
      {data.length > 0 ? (
        <div>
          {data.map((data, index) => (
            <DietCardView key={index} data={data} />
          ))}
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <h4 className="">No data</h4>
        </div>
      )}
    </div>
  );
}

const DietCardView = ({ data }) => {
  const GetDate = (date) => {
    var today = moment().format("DD-MMM-YYYY");
    var yesterday = moment(today).subtract(1, "day");
    var formatedDate = moment(date).format("DD-MMM-YYYY");

    if (moment(formatedDate).isSame(today, "day")) {
      return "today " + formatedDate;
    } else if (moment(formatedDate).isSame(yesterday, "day")) {
      return "yesterday " + formatedDate;
    }

    return "day after " + formatedDate;
  };
  const handleTime = (date, data) => {
    let time = data;
    if (time.length == 4) {
      time = "0" + time;
    }
    let datem = moment(date).format("yyyy-MM-DD");
    return moment(datem + "T" + time + ":00").format("hh:mm a");
  };
  return (
    <div className="my-2">
      <h3 className="text-white text-xl">
        Diet plan for {GetDate(data.rxDate)}
      </h3>
      <div className="w-full flex flex-col bg-white rounded-md">
        {data.diets.map((d, i) => (
          <div key={i}>
            {i % 2 !== 0 ? (
              <div className="w-full h-48 flex items-center justify-between ">
                <div className="w-[40%] p-2 flex items-center justify-center flex-col">
                  <h2 className="text-orange-v2 py-2">
                    {handleTime(data.rxDate, d[0].time)}
                  </h2>
                  <div>
                    {d.map((data, index) => (
                      <h2 key={index}>{data.diet}</h2>
                    ))}
                  </div>
                </div>

                <div className=" h-full w-[20%] flex flex-col items-center justify-center">
                  <div className=" w-1 h-full bg-gray-300"></div>
                  <div className=" bg-orange-v2 rounded-full p-2 "></div>
                  <div className=" w-1 h-full bg-gray-300"></div>
                </div>
                <div className="w-[40%] p-2 flex items-center justify-center">
                  <div
                    className={` grid ${
                      d.length > 1 ? " grid-cols-2" : " grid-cols-1"
                    } gap-1 w-24 h-24`}
                  >
                    {d.map((data, index) => (
                      <img
                        key={index}
                        src={data.URL}
                        className="w-full h-max"
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full h-48 flex items-center justify-between ">
                <div className="w-[40%] p-2 flex items-center justify-center">
                  <div
                    className={` grid ${
                      d.length > 1 ? " grid-cols-2" : " grid-cols-1"
                    } gap-1 w-24 h-24`}
                  >
                    {d.map((data, index) => (
                      <img
                        key={index}
                        src={data.URL}
                        className="w-full h-max"
                      />
                    ))}
                  </div>
                </div>
                <div className=" h-full w-[20%] flex flex-col items-center justify-center">
                  <div className=" w-1 h-full bg-gray-300"></div>
                  <div className=" bg-orange-v2 rounded-full p-2 "></div>
                  <div className=" w-1 h-full bg-gray-300"></div>
                </div>
                <div className="w-[40%] p-2 flex items-center justify-center flex-col">
                  <h2 className="text-orange-v2 py-2">
                    {handleTime(data.rxDate, d[0].time)}
                  </h2>
                  <div>
                    {d.map((data, index) => (
                      <h2 key={index}>{data.diet}</h2>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
