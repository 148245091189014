import { useSelector } from "react-redux";
import Loader from "./Loader";
import { useLayoutEffect, useState } from "react";

export default function LoadingModal() {
    const data =  useSelector(state=>state.loading);
    const [isActive,setActive]=useState(false);
    const [isOtpVerified,setOtp]=useState(false);

    useLayoutEffect(()=>{
        if(data!=null){
            setActive(data.loading)
            setOtp(data.otp)
        }
    },[data])
  return (
    <>
      <div style={{display:isActive?"flex":"none"}} className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-40">
        <div className="">
          <Loader />
          <div className="flex items-center justify-center mt-10" style={{display:isOtpVerified?"flex":"none"}}>
            <img src="./assets/verify.svg" className=" w-11"/>
            <h1 className="text-lg text-thGreen ml-2">Verified</h1>
          </div>
        </div>
      </div>
    </>
  );
}
