import { useState } from "react";
import {AiOutlineDownload}from "react-icons/ai"
export default function Prescription() {
    const [tab,setTab]=useState("in");
    const [inprogres,setInprogress]=useState([
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
    ])
    const [history,setHistory]=useState([
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
        {
            id:1,
            name:"Medicine name",
            rxDate:"1 april 2022",
            freq:"Night",
            dose:"0-0-0-1",
            dosemesure:"Tab",
            dureationDays:"90",
            note:"Some notes to show"
        },
    ])
  return (
    <>
      <div className="container h-screen max-w-md mx-auto p-2 bg-primary text-white  overflow-scroll">
            <div className="flex rounded-xl bg-white p-2 items-center justify-evenly">
                <div onClick={()=>setTab("in")} className="text-gray-300 p-1 w-28 flex items-center justify-center">
                    <h1 className={`${tab=="in"?"text-primary":"text-gray-300"}`}>In Progress</h1>
                </div>
                <div class="border border-gray-300 h-8"></div>
                <div onClick={()=>setTab("history")} className="text-gray-300 p-1 w-28 flex items-center justify-center">
                    <h1 className={`${tab=="history"?"text-primary":"text-gray-300"}`}>History</h1>
                </div>
            </div>
            <div className="mt-4">
                {tab=="in"?inprogres.map((value,index)=>{
                    return(
                        <div key={index} className="bg-white rounded-xl p-4 mt-4">
                            <div className="flex justify-between items-center">
                                <h1 className="text-primary">{value.name.slice(0,20)}...</h1>
                                <div className="flex items-center justify-center rounded-lg p-1 bg-gray-200"> 
                                <AiOutlineDownload className="text-thtext mr-1"/>
                                    <h1 className="text-thtext text-sm">Dawnload PDF</h1>
                                </div>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">Rx date</h1>
                                <h1 className="text-primary text-sm">{value.rxDate}</h1>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">Frequency</h1>
                                <h1 className="text-primary text-sm">{value.freq}</h1>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">Dose</h1>
                                <h1 className="text-primary text-sm">{value.dose}</h1>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">Dose Measure</h1>
                                <h1 className="text-primary text-sm">{value.dosemesure}</h1>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">{"Duration (in days)"}</h1>
                                <h1 className="text-primary text-sm">{value.dureationDays}</h1>
                            </div>
                            <div className="flex justify-between mt-2">
                                <h1 className="text-black text-sm">Note</h1>
                                <h1 className="text-primary text-sm">{value.note}</h1>
                            </div>
                        </div>
                    )
                }):history.map((value,index)=>{
                    return(
                        <div key={index} className="bg-white rounded-xl p-4 mt-4">
                        <div className="flex justify-between items-center">
                            <h1 className="text-primary">{value.name.slice(0,20)}...</h1>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-gray-200"> 
                            <AiOutlineDownload className="text-thtext mr-1"/>
                                <h1 className="text-thtext text-sm">Dawnload PDF</h1>
                            </div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">Rx date</h1>
                            <h1 className="text-primary text-sm">{value.rxDate}</h1>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">Frequency</h1>
                            <h1 className="text-primary text-sm">{value.freq}</h1>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">Dose</h1>
                            <h1 className="text-primary text-sm">{value.dose}</h1>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">Dose Measure</h1>
                            <h1 className="text-primary text-sm">{value.dosemesure}</h1>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">{"Duration (in days)"}</h1>
                            <h1 className="text-primary text-sm">{value.dureationDays}</h1>
                        </div>
                        <div className="flex justify-between mt-2">
                            <h1 className="text-black text-sm">Note</h1>
                            <h1 className="text-primary text-sm">{value.note}</h1>
                        </div>
                    </div>
                    )
                })}
            </div>
      </div>
    </>
  );
}
