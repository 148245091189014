import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  PostMileStoneStatus,
  PostMileStoneVideo,
  normalHeader,
} from "../../../utils/Api";
import moment from "moment";
import { toast } from "react-toastify";
import {
  AiOutlineTrophy,
  AiOutlineDelete,
  AiOutlineCamera,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import WarningPopup from "./WarningDialog";
import { useRef } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export default function MileStoneFarmPage() {
  const MileStoneData = useSelector((state) => state.Milestone);
  const [data, setData] = useState(MileStoneData.data);
  const [activeBtnIndex, setActiveBtnIndex] = useState(
    MileStoneData.data.Intervention
  );
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileContentType, setFileContentType] = useState("");
  const [Notes, setNotes] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [StatusID, setStatusId] = useState("");
  const [isPreView, setIsPreView] = useState(false);
  const [openWarning, setIsOpenwarning] = useState(false);
  const [ifChangeData, setIfChangeData] = useState();
  const [warningMessage, setWarningMessage] = useState("");
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const router = useNavigate();
  const fileRef = useRef(null);
  const { AuthToken, UserId } = MileStoneData;

  useEffect(() => {
    if (MileStoneData.data == null) {
      router("/milestone-and-growth/milestone");
    } else {
      let data = MileStoneData.data;
      if (data.AchvdDate != null) {
        setDate(data.AchvdDate);
      }
      if (data.notes != "") {
        setIsPreView(true);
        setNotes(data.notes);
      }
      if (data.attachment != "") {
        setIsPreView(true);
        setFileUrl(data.attachment);
        setFileName(data.attachment);
      }
      if (data.Intervention != null) {
        setActiveBtnIndex(data.Intervention);
      }
      if (data.webRecordId != "") {
        setStatusId(data.webRecordId);
      }
    }
  }, []);

  const handleClick = (status) => {
    if (activeBtnIndex == -1 && status == -1) return;
    if (status == -1 && fileUrl != "") {
      setWarningMessage(
        "Are you sure you want to remove the uploaded image/video?"
      );
      let dat = {
        status,
        id:StatusID,
      };
      setIfChangeData(dat);
      setIsOpenwarning(true);
      return;
    }
    if ((status != -1 && date == null) || (status != -1 && date == "")) {
      toast.error("Please select achieved date.");
      return;
    }
    setActiveBtnIndex(status);
    let header = {
      ...normalHeader,
      AuthToken,
      userId: UserId,
    };
    let d = {
      patientId: UserId,
      type: "Goal",
      webRecordId: StatusID,
      isAgree: status,
      achievedDate: moment(date).format("yyyy-MM-DD"),
    };
    // console.log(header);
    console.log(d);
    PostMileStoneStatus(d, header).then((res) => {
      if (res.status) {
        toast.success("Milestone status updated.");
      } else {
        toast.error("Failed to update goal status.");
      }
    });
  };
  const choseFile = () => {
    if (fileRef.current != null) {
      setFile(null);
      setFileUrl("");
      fileRef.current.value = "";
      fileRef.current.click();
    }
  };
  const onfileChange = (e) => {
    if (e.target.files[0] != null) {
      let file = e.target.files[0];
      if (file.size > 15728640) {
        toast.warn("File size is too big please select under 15mb.");
        return;
      }
      setFileName(file.name);
      setFileSize(file.size);
      setFileContentType(file.type);
      setIsNewFileSelected(true);
      setFileUrl((pre) => URL.createObjectURL(file));
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64 = e.target.result;
          let string = extractBase64FromDataUrl(base64);
          setFile(string);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const extractBase64FromDataUrl = (dataUrl) => {
    const parts = dataUrl.split(",");
    if (parts.length === 2) {
      return parts[1];
    } else {
      throw new Error("Invalid data URL");
    }
  };
  const handleFileDeleted = () => {
    setFile(null);
    setFileUrl("");
    fileRef.current.value = "";
  };
  const GetDate = (date) => {
    return moment(date).format("DD-MMM-YYYY");
  };

  const OnSubmit = () => {
    if (activeBtnIndex == null || activeBtnIndex == 2 || activeBtnIndex == "") {
      toast.error("Please select sometimes/frequently to update.");
      return;
    }
    if (activeBtnIndex == -1) {
      toast.error("Please select sometimes/frequently to update.");
      return;
    }

    let header = {
      ...normalHeader,
      AuthToken,
      userId: UserId,
    };
    let datas = {
      patientId: UserId,
      GoalObservationID: data.GoalObservationID,
      createdBy: UserId,
      fileContent: file,
      fileName,
      fileSize,
      fileContentType,
      notes: Notes,
    };
    let d = {
      patientId: UserId,
      type: "Goal",
      webRecordId: StatusID,
      isAgree: activeBtnIndex,
      achievedDate: moment(date).format("yyyy-MM-DD"),
    };
    PostMileStoneStatus(d, header).then((res) => {
      if (res.status) {
        toast.success("Milestone status updated.");
        PostMileStoneVideo(JSON.stringify(datas), header).then((res) => {
          if (res.status) {
            router(-1);
          }
        });
      } else {
        toast.error("Failed to update milestone status.");
      }
    });
  };
  const handleClose = (response) => {
    setIsOpenwarning(false);
    if (response) {
      let header = {
        ...normalHeader,
        AuthToken,
        userId: UserId,
      };
      let d = {
        patientId: UserId,
        type: "Goal",
        webRecordId: ifChangeData.id,
        isAgree: ifChangeData.status,
        achievedDate: moment(date).format("yyyy-MM-DD"),
      };
      setActiveBtnIndex(ifChangeData.status);
      PostMileStoneStatus(d, header).then((res) => {
        if (res.status) {
          toast.success("Milestone status updated.");
          router(-1);
        } else {
          toast.error("Failed to update goal status.");
        }
      });
    }
  };
  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light overflow-scroll p-4">
      <WarningPopup
        isOpen={openWarning}
        message={warningMessage}
        onClose={handleClose}
      />
      {isPreView ? (
        <div className={`w-full rounded-md bg-gray-200 mb-2 `}>
          <div className="flex">
            <div className="p-2">
              <AiOutlineTrophy
                className={`w-8 h-8 ${
                  data?.Goalstatus == "O"
                    ? "text-red-500"
                    : data?.Goalstatus == "U"
                    ? "text-yellow-500"
                    : data?.Goalstatus == "D"
                    ? "text-green-500"
                    : ""
                }`}
              />
            </div>
            <div className="px-3 w-full py-2">
              <div>
                <h4 className="text-sm">{data?.GoalName}</h4>
              </div>
              <div className="flex text-xs items-center justify-between">
                <h5>Due on {GetDate(data?.RxDate)}</h5>
                {data?.AchvdDate != "" && (
                  <h5>Achieved on {GetDate(data?.AchvdDate)}</h5>
                )}
              </div>
              <div className="w-full flex items-center justify-between pt-2">
                <button
                  onClick={() => handleClick(-1, data.webRecordId)}
                  className={`w-max  text-sm  px-3 py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == -1
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Not yet
                </button>
                <button
                  onClick={() => handleClick(3, data.webRecordId)}
                  className={`w-max px-3  text-sm  py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == 3
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Sometime
                </button>
                <button
                  onClick={() => handleClick(1, data.webRecordId)}
                  className={`w-max px-3 text-sm py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == 1
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Frequently
                </button>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="flex items-center px-2 border-b border-gray-300">
              <h2 className="w-32 text-sm">Achieved on:</h2>
              <DatePicker
                format="dd/MM/yyyy"
                maxDate={new Date()}
                value={date}
                onChange={setDate}
                className="w-full bg-transparent"
              />
            </div>
            <h2 className="w-max">
              {isNewFileSelected
                ? "Upload image/video"
                : "Uploaded image/video"}
            </h2>
            <input
              type="file"
              accept="video/*, image/*"
              ref={fileRef}
              className=" hidden w-full h-9"
              onChange={onfileChange}
            />
            <div className="w-full ">
              {fileUrl != "" && (
                <div>
                  {fileName.includes(".mp4") ? (
                    <video className="w-full" controls>
                      <source src={fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      alt="upload-image"
                      src={fileUrl}
                      className="w-full max-h-60"
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-2">
              {isNewFileSelected && (
                <AiOutlineDelete
                  onClick={handleFileDeleted}
                  className="w-6 h-6 text-gray-400"
                />
              )}
              <AiOutlineCamera
                onClick={choseFile}
                className="w-6 h-6 text-gray-400"
              />
            </div>
          </div>
          <div className="p-2">
            <div className=" h-32 border border-gray-300 rounded-md p-2 my-4">
              <textarea
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Tell us more about this memory 250 characters"
                className=" bg-transparent w-full h-full outline-none"
              />
            </div>
          </div>
          <div className="p-2 flex items-center justify-between space-x-2">
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={() => router(-1)}
            >
              Back
            </button>
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={OnSubmit}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <div className={`w-full rounded-md bg-gray-200 mb-2 `}>
          <div className="flex">
            <div className="p-2">
              <AiOutlineTrophy
                className={`w-8 h-8 ${
                  data?.Goalstatus == "O"
                    ? "text-red-500"
                    : data?.Goalstatus == "U"
                    ? "text-yellow-500"
                    : data?.Goalstatus == "D"
                    ? "text-green-500"
                    : ""
                }`}
              />
            </div>
            <div className="px-3 w-full py-2">
              <div>
                <h4 className="text-sm">{data?.GoalName}</h4>
              </div>
              <div className="flex text-xs items-center justify-between">
                <h5>Due on {GetDate(data?.RxDate)}</h5>
                {data?.AchvdDate != "" && (
                  <h5>Acchived on {GetDate(data?.AchvdDate)}</h5>
                )}
              </div>
              <div className="w-full flex items-center justify-between pt-2">
                <button
                  onClick={() => handleClick(-1, data.GoalObservationID)}
                  className={`w-max  text-sm  px-3 py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == -1
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Not yet
                </button>
                <button
                  onClick={() => handleClick(3, data.GoalObservationID)}
                  className={`w-max px-3  text-sm  py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == 3
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Sometime
                </button>
                <button
                  onClick={() => handleClick(1, data.GoalObservationID)}
                  className={`w-max px-3 text-sm py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                    activeBtnIndex == 1
                      ? "bg-blue-dark text-white"
                      : " bg-transparent"
                  }`}
                >
                  Frequently
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center px-2 border-b border-gray-300">
            <h2 className="w-32 text-sm">Achieved on:</h2>
            <DatePicker
              format="dd/MM/yyyy"
              maxDate={new Date()}
              value={date}
              onChange={setDate}
              className="w-full bg-transparent"
            />
          </div>
          <div className="p-2">
            <h2 className="w-max">
              {isNewFileSelected
                ? "Upload image/video"
                : "Uploaded image/video"}
            </h2>
            <input
              type="file"
              accept="video/*, image/*"
              ref={fileRef}
              className=" hidden w-full h-9"
              onChange={onfileChange}
            />
            <div className="w-full ">
              {fileUrl != "" && (
                <div>
                  {fileName.includes(".mp4") ? (
                    <video className="w-full" controls>
                      <source src={fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      alt="upload-image"
                      src={fileUrl}
                      className="w-full max-h-60"
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-2">
              <AiOutlineDelete
                onClick={handleFileDeleted}
                className="w-6 h-6 text-gray-400"
              />
              <AiOutlineCamera
                onClick={choseFile}
                className="w-6 h-6 text-gray-400"
              />
            </div>
          </div>
          <div className="p-2">
            <div className=" h-32 border border-gray-300 rounded-md p-2 my-4">
              <textarea
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Tell us more about this memory 250 characters"
                className=" bg-transparent w-full h-full outline-none"
              />
            </div>
          </div>
          <div className="p-2 flex items-center justify-between space-x-2">
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={() => router(-1)}
            >
              Back
            </button>
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={OnSubmit}
            >
              SAVE
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
