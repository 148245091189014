
//Api data

export const BaseUrl ="http://testapi.gleeohealth.com";
export const VIDEO_CALL_URL ="stageapi.gleeohealth.com";
export const BasePrefix=BaseUrl+"/api/App"
export const GetDataByNumber =BasePrefix+"/AddGuestPhone";
export const PostBasicData =BasePrefix+"/ModifyGuestInfo";
export const GetFamilyData =BasePrefix+"/GetUserPlans";
export const GetAllRetaion =BasePrefix+"/GetAllRelation";
export const POST_APPOINTMENT_INFO =BasePrefix+"/AddBookConsultAppointmentInfo";
export const GET_ALL_SLOTS =BasePrefix+"/GetAllSlot/";
export const GET_ALL_TIME_SLOTS =BasePrefix+"/GetAllSlotTime";
export const HANDLE_GUEST_ADD =BasePrefix+"/AddGuestProfile";

//calls
export const HANDLE_AUDIO_CALL =BaseUrl+"/api/Knowlarity/JoinCall";

//plans
export const GET_PLAN_BY_ID =BaseUrl+"/api/Cart/GetItemsById";
export const ADD_TO_CART =BaseUrl+"/api/Cart/Add2Cart";
export const UPDATE_CART_STATUS =BaseUrl+"/api/Cart/UpdateCartStatus";
export const GET_PAYMENT_DETAILS =BaseUrl+"/api/Cart/GetCartStatus";

export const GET_VIDEO_CALL_LOGS =BaseUrl+"/api/Alerts/GetVideoLog";

//milestone and growth
export const GET_GOAL_LIST =BaseUrl+"/api/Goal/GetMilestoneListInfo";
export const GET_GROWTH_TRACKER =BaseUrl+"/api/Goal/GetGrowthTracker";
export const POST_GOAL_STATUS =BaseUrl+"/api/Notifications/ModifyNotificationUserStatus";
export const POST_GOAL_FORM =BaseUrl+"/api/Goal/AddGoalVideoInfo";
export const POST_GROWTH_DATA =BaseUrl+"/api/Goal/AddGrowthData";
export const GET_DIET_PLAN =BaseUrl+"/api/App/GetDietPlan";
export const GET_VACTINATION_PLAN =BaseUrl+"/api/App/GetVaccineDetail";
export const POST_VACTINATION_STATUS =BaseUrl+"/api/Notifications/ModifyNotificationUserStatus";
export const GROWTH_DELETE =BaseUrl+"/api/Goal/DeleteGrowth";

//slider
export const SLIDER =BaseUrl+"/api/HomePage/AddHomeBannerInfo";


//payemets info



export const PAYMENT_URL="https://test.ccavenue.com";
// export const PAYMENT_URL="https://secure.ccavenue.com";
export const MID="770310";
export const ACCESS_CODE="AVKD93KG91CJ56DKJC";
export const ENCRIPTION_KEY="20016AA3C89E3F00B4D4C794B3DADD45";