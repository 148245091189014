import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { BiInjection, BiSolidInjection } from "react-icons/bi";
import moment from "moment";
import { useRef } from "react";
import { toast } from "react-toastify";
import { AiOutlineDelete, AiOutlineCamera } from "react-icons/ai";
import { PostVactinationVid, normalHeader } from "../../../utils/Api";
import Vactination from "./Vactination";

export default function () {
  const VatinationData = useSelector((state) => state.Milestone);
  const { UserId, AuthToken } = VatinationData;
  const [data, setData] = useState(VatinationData.data);
  const [date, setDate] = useState();
  const router = useNavigate();
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [Notes, setNotes] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isPreView, setIsPreView] = useState(false);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  useEffect(() => {
    if (VatinationData.data == null) {
      router("/milestone-and-growth/vaccination");
    } else {
      if (VatinationData.data.TakenOn != "") {
        setDate(GetDate(VatinationData.data.TakenOn));
      }
      if (data != null) {
        console.log(data);
        setNotes(data.notes);
        setFileName(data.fileName);
        setFileUrl(data.fileName);
      }
    }
  }, [VatinationData]);

  const choseFile = () => {
    if (fileRef.current != null) {
      setFile(null);
      setFileUrl("");
      fileRef.current.value = "";
      fileRef.current.click();
    }
  };
  const onfileChange = (e) => {
    if (e.target.files[0] != null) {
      let file = e.target.files[0];
      if (file.size > 15728640) {
        toast.warn("File size is too big please select under 15mb.");
        return;
      }
      setFileName(file.name);
      setFileSize(file.size);
      setIsNewFileSelected(true);
      setFileUrl((pre) => URL.createObjectURL(file));
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64 = e.target.result;
          let string = extractBase64FromDataUrl(base64);
          setFile(string);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const extractBase64FromDataUrl = (dataUrl) => {
    const parts = dataUrl.split(",");
    if (parts.length === 2) {
      return parts[1];
    } else {
      throw new Error("Invalid data URL");
    }
  };
  const handleFileDeleted = () => {
    setFile(null);
    setFileUrl("");
    fileRef.current.value = "";
  };
  const OnSubmit = () => {
    if (date == null) {
      toast.error("Please select date.");
      return;
    }
    if (handleUpcomingDateLimit()) {
      toast.error("Upcoming date is too far.");
      return;
    }
    let header = {
      ...normalHeader,
      AuthToken,
      userId: UserId,
    };
    let datas = {
      type: "Vaccination",
      patientId: UserId,
      webRecordId: data.vaccineId,
      achievedDate: GetDate(date),
      fileContent: file,
      fileName,
      fileSize,
      notes: Notes,
    };
    console.log(datas);
    PostVactinationVid(JSON.stringify(datas), header).then((res) => {
      console.log(res);
      if (res.status) {
        router(-1);
      }
    });
  };
  const handleUpcomingDateLimit = () => {
    let DueDate = GetDate(VatinationData.data.DueOn);
    let todayDate = GetDate(new Date());

    const date1 = moment(todayDate);
    const date2 = moment(DueDate);

    // Calculate the day difference
    const dayDifference = date2.diff(date1, "days");
    // console.log(DueDate + " " + todayDate + " " + dayDifference);

    if (dayDifference > 3) {
      return true;
    }
    return false;
  };
  const GetDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light overflow-scroll p-2">
      <div className="bg-white p-2 rounded-md">
        <div className="flex items-center justify-between cursor-pointer">
          <div className="p-2 flex items-center space-x-2">
            <div>
              <BiInjection
                size={22}
                className={`${
                  data.DueStatus == "Overdue"
                    ? "text-red-500"
                    : data.DueStatus == "Upcoming"
                    ? "text-yellow-500"
                    : data.DueStatus == "Taken"
                    ? "text-green-500"
                    : "text-blue-dark"
                }`}
              />
            </div>
            <div className="flex items-center space-x-1 text-sm">
              <h3>{data.VaccineName}</h3>
              <h3>{data.Dose}</h3>
            </div>
          </div>
          {/* <div className="flex items-center space-x-2 px-2">
          <div className="text-sm">
            <h2>{date}</h2>
          </div>
          <div className="text-gray-600">
            <IoIosArrowDown size={20} />
          </div>
        </div> */}
        </div>
        <div className="border-b flex items-center p-1">
          <h2 className="w-1/3">Taken on</h2>
          <DatePicker
            format="dd/MM/yyyy"
            maxDate={new Date()}
            value={date}
            onChange={setDate}
            className="w-full bg-transparent"
          />
        </div>
        <div className="my-2">
          <div className="w-full">
            <h2 className="font-bold ">Save Vaccination tag</h2>
            <p></p>
          </div>
          <input
            type="file"
            accept="image/*,video/*"
            ref={fileRef}
            className=" hidden w-full h-9"
            onChange={onfileChange}
          />
          <div className="w-full ">
            {fileUrl != "" && (
              <div>
                {fileName.includes(".mp4") ? (
                  <video className="w-full" controls>
                    <source src={fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={fileUrl} alt="image" className="w-full " />
                )}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between mt-2">
            {isNewFileSelected && (
              <AiOutlineDelete
                onClick={handleFileDeleted}
                className="w-6 h-6 text-gray-400"
              />
            )}
            <AiOutlineCamera
              onClick={choseFile}
              className="w-6 h-6 text-gray-400"
            />
          </div>
          <div className="p-2">
            <div className=" h-32 border border-gray-300 rounded-md p-2 my-4">
              <textarea
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add note"
                className=" bg-transparent w-full h-full outline-none"
              />
            </div>
          </div>
          <div className="p-2 flex items-center justify-between space-x-2">
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={() => router(-1)}
            >
              Back
            </button>
            <button
              className=" rounded-full text-white bg-blue-dark w-full h-10"
              onClick={OnSubmit}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
