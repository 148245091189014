
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function LayoutMilestone() {
  return (
    <div className="w-screen h-screen">
        <Outlet/>
    </div>
  )
}
