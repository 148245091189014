import { JitsiMeeting } from "@jitsi/react-sdk";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

function loading() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
}

export default function VideoCall() {
    const VideoData =  useSelector(state=>state.VideoCall)
    const Basic =  useSelector(state=>state.Basic)
  return (
    <div className="w-screen h-screen ">
      <JitsiMeeting
        domain={"meet.jit.si"}
        roomName={VideoData.roomName}
        spinner={loading}
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        userInfo={{
          displayName: VideoData.name,
        }}
        onApiReady={(externalApi) => {
            
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "100vh";
        }}
      />
    </div>
  );
}
