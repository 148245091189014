import React, { useState } from "react";
import { GrAdd } from "react-icons/gr";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { AddGrowthData,normalHeader } from "../utils/Api";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css'
import moment from "moment";

const GrowthPopUp = ({ isOpen, onClose, data,AuthToken,userId,setRefreshData }) => {
  const key = "fdgjdfjfkhkgfk";

  const [date, setDate] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [headCirc, setHeadCirc] = useState("");

  useEffect(() => {
    setDate("");
    setHeadCirc("");
    setHeight("");
    setWeight("");
  }, [isOpen]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let header ={
        ...normalHeader,
        AuthToken,
        userId
    }
    let data={
        userId,
        Author:userId,
        PatientID:userId,
        Height:height,
        Weight:weight,
        HeadCircum:headCirc,
        achievedDate:moment(date).format("yyyy-MM-DD")
    }
    console.log(data);
    AddGrowthData(data,header).then(res=>{
        console.log(res);
        if(res.status){
          onClose(false);
          setRefreshData(pre=>!pre)
         
          toast.success(res.message)
        }else{
          toast.error(res.message)

        }
    })
  };
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? "" : "hidden"}`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0  flex items-center justify-center">
        <AnimatePresence>
          <motion.div
            className="fixed bottom-0 max-w-md w-full  bg-gray-300 p-6 rounded-t-lg "
            key={key}
            initial={{ y: "100%" }}
            animate={{ y: isOpen ? 0 : "100%" }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
          >
            <div className="flex flex-col justify-center">
              <div className="flex w-full items-center justify-center font-bold text-xl h-8 mb-4">
                <h3>Add Growth Details</h3>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="w-full flex items-center space-x-2 bg-white rounded-md p-2">
                  <h3 className=" font-bold w-28">Date:</h3>
                   <DatePicker format="dd/MM/yyyy" required maxDate={new Date()} value={date} onChange={setDate} className="w-full bg-transparent" />
                </div>
                {data == 1 && (
                  <div className="w-full flex items-center space-x-2 bg-white rounded-md p-2">
                    <h3 className=" font-bold w-28">Weight:</h3>
                    <input
                    type="number"
                    step="0.01"
                      required
                      placeholder="Enter weight"
                      className="w-full"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                    <h3>kg</h3>
                  </div>
                )}
                {data == 2 && (
                  <div className="w-full flex items-center space-x-2 bg-white rounded-md p-2">
                    <h3 className=" font-bold w-32">Height:</h3>
                    <input
                      type="number"
                      step="0.01"
                      required
                      placeholder="Enter height"
                      className="w-full"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                    <select className="px-2">
                      <option value={"cm"}>cm</option>
                    </select>
                  </div>
                )}
                {data == 3 && (
                  <div className="w-full flex items-center space-x-2 bg-white rounded-md p-2">
                    <h3 className=" font-bold w-32">Head circ:</h3>
                    <input
                      type="number"
                      step="0.01"
                      required
                      placeholder="Enter head circ"
                      className="w-full"
                      value={headCirc}
                      onChange={(e) => setHeadCirc(e.target.value)}
                    />
                    <select className="px-2">
                      <option value={"cm"}>cm</option>
                    </select>
                  </div>
                )}
                <div className="w-full flex items-center justify-end space-x-2">
                  <button
                    onClick={onClose}
                    className="px-8 py-2 bg-blue-dark rounded-md text-white"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="px-8 py-2 bg-blue-dark rounded-md text-white"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default GrowthPopUp;
