// components/ChildHelpPage.js
import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PostApointMent } from "../utils/Api";
import { setBasicData, setConsultData, setTempData } from "../Redux/Actions/Actions";

const DoctorConsultation = () => {
  const navigate = useNavigate();
  const Basic = useSelector((state) => state.Basic);
  const UserData = useSelector((state) => state.User);
  const Temp = useSelector((state) => state.Temp);
  const [type, setType] = useState("audio");
  const [languuage, setLanguage] = useState("english");
  const [isPayement, setIsPayement] = useState(false);
  const Dispacth = useDispatch();


  useLayoutEffect(()=>{
    if(Temp.languages!=null){
      setLanguage(Temp.languages);
      setType(Temp.consultType);
      setIsPayement(false)
    }
  },[])


  const handleNotFree = () => {
    Dispacth(setTempData({null:"null"}))
    navigate("/doctor-apointment");
  };
  const handleType = (value) => {
    setType(value);
  };
  const handlelanguage = (value) => {
    setLanguage(value);
  };
  const GetIn24 = () => {
    return moment().format("HH:mm");
  };
  const handleContinue = () => {
    let data = {
      guestId: Basic.id,
      consultType: type,
      consultationDate: moment().format("yyyy-MM-DD"),
      slot: GetIn24(),
      FromTime: GetIn24(),
      ToTime: GetIn24(),
      languages: languuage,
      userType: Basic.type,
      DoctorId: "-1",
      helpQuestion1: Basic.qs,
      AppointmentReqID:Basic.appReqId
    };
    const normalHeader={
      'Content-Type':'application/json',
      device:"Android",
      Accept:"application/json",
      CorrelationId:1234551516516565,
      AuthToken:Basic.authToken,
      userId:Basic.id
  }
    PostApointMent(normalHeader,data).then((r) =>
      r.json().then((d) => {
        console.log(d);
        console.log(data);
        if (d.status) {
          let tempBasic = Basic;
          tempBasic.appReqId=d.appReqId
          data.number =Basic.code+""+Basic.mobileNumber
          Dispacth(setTempData(data))
          Dispacth(setConsultData({null:"null"}))
          Dispacth(setBasicData(tempBasic))
          setIsPayement(true);
        }
      })
    );

    //
  };
  const HandlePayment = () => {
    navigate("/payement-page");
  };

  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      <h1 className="text-2xl font-bold mb-4">
        Take a doctor consultation call
      </h1>
      <p>Doctors are available within the next few minutes</p>
      <div className="h-3/4 flex flex-col justify-between">
        <div>
          <div className="w-full bg-primary rounded-2xl mt-4 p-4">
            <h1 className="text-white ">
              Your Doctor is ready now for consultation call
            </h1>
            <h1 className="text-white text-sm mt-4">Select type</h1>
            <div className="flex mt-2">
              <div
                onClick={() => {
                  handleType("audio");
                }}
                className={`rounded-full ${
                  type == "audio" ? "bg-thBlue" : "bg-transparent"
                } px-8 py-1 border border-white cursor-pointer`}
              >
                <h1 className="text-white text-sm">Audio</h1>
              </div>
              <div
                onClick={() => {
                  handleType("video");
                }}
                className={`rounded-full ${
                  type == "video" ? "bg-thBlue" : "bg-transparent"
                } px-8 py-1 border border-white ml-4 cursor-pointer`}
              >
                <h1 className="text-white text-sm">Video</h1>
              </div>
            </div>
            <h1 className="text-white text-sm mt-4">Select language</h1>
            <div className="flex mt-2">
              <div
                onClick={() => {
                  handlelanguage("english");
                }}
                className={`rounded-full ${
                  languuage == "english" ? "bg-thBlue" : "bg-transparent"
                } px-8 py-1 border border-white cursor-pointer`}
              >
                <h1 className="text-white text-sm">English</h1>
              </div>
              <div
                onClick={() => {
                  handlelanguage("hindi");
                }}
                className={`rounded-full ${
                  languuage == "hindi" ? "bg-thBlue" : "bg-transparent"
                } px-8 py-1 border border-white ml-4 cursor-pointer`}
              >
                <h1 className="text-white text-sm">Hindi</h1>
              </div>
            </div>
          </div>
          <h1
            onClick={handleNotFree}
            className="text-sm mt-2 ml-2 cursor-pointer"
          >
            Not free right now?
          </h1>
        </div>
        <div className="flex">
          <button
            className=" bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            style={{ display: isPayement ? "none" : "flex" }}
            onClick={handleContinue}
          >
            Confirm
          </button>
          <button
            className="bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            style={{ display: !isPayement ? "none" : "flex" }}
            onClick={HandlePayment}
          >
            Make Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default DoctorConsultation;
