import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HandleNumber } from "../../utils/Api";
import {
  setBasicData,
  setUserData,
  setLoading,
  setOtpVerifiedLaoding,
  setOtp,
  setMainUser,
} from "../../Redux/Actions/Actions";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast } from "react-toastify";
import { useEffect } from "react";


export default function EnterOtp() {
  const OtpCheck = useSelector((state) => state.Otp);
  const Basic = useSelector((state) => state.Basic);
  const [OTP, setSetOTP] = useState("");
  const navigate = useNavigate();
  const Dispatch = useDispatch();

  useEffect(()=>{
    Dispatch(setLoading({loading:false,otp:false}))
  },[])


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (OTP === "" && OTP === null) return;
    Dispatch(setLoading({loading:true,otp:false}))
    try {
      await OtpCheck.confirm(OTP);
      Dispatch(setLoading({loading:true,otp:true}))
      handleNumber(Basic.code, Basic.number);
    } catch (error) {
      setOtp("")
      toast.error("Please enter correct OTP.")
      Dispatch(setLoading({loading:false,otp:false}))
    }
  };

  const handleNumber = (code, number) => {
    HandleNumber({
      mobileCode: code,
      mobileNumber: number,
    }).then((res) => {
      res.json().then((d) => {
        console.log(d);
        if (d.status == true) {
          let id = d.userID;
          Dispatch(
            setUserData(d)
          );
          if (d.Gender != null) {
           
            Dispatch(
              setBasicData({
                type: d.type,
                id: id,
                code: code,
                name:d.firstName,
                mobileNumber: number,
                Gender: d.Gender,
                authToken:d.authToken,
              })
            );
            Dispatch(setMainUser(d))
            navigate("/details");
          } else {
            if(d.message="Guest already exist"){
              Dispatch(
                setBasicData({
                  type: d.type,
                  id: id,
                  code: code,
                  mobileNumber: number,
                  ConnectionID: id,
                  rid:d.userID,
                  authToken:d.authToken,
                })
              );
            }else{
              Dispatch(
                setBasicData({
                  type: d.type,
                  id: id,
                  code: code,
                  mobileNumber: number,
                  ConnectionID: id,
                  authToken:d.authToken,
                })
              );
            }
           
            Dispatch(setMainUser(d))
            navigate("/details");
          }
        }
      });
    });
  };

  const onCapthcaVerify=(number)=>{
    const verifier = new RecaptchaVerifier(auth,'recaptcha-container', {'size': 'invisible',});
    verifier.render();
    return signInWithPhoneNumber(auth,number,verifier)
  }
  const handleResendOtp=async()=>{
    let num = Basic.code+""+Basic.number
    Dispatch(setLoading(true))
    await onCapthcaVerify(num).then(res=>{
      toast.success("OTP resend successfull")
      Dispatch(setOtp(res));
      Dispatch(setLoading(false))
    })
  }

  return (
    <div className="flex justify-center items-center text-thtext bg-gray-100">
      <div className="min-h-screen container items-center ">
        <div className="h-screen flex flex-col justify-start items-center ">
          <div className="max-w-md w-full h-44 bg-otpbg">
            <img
              className=" w-full h-full pt-5"
              src="./assets/otpmessage.svg"
            />
          </div>
          <div className="px-8 pt-6 pb-8 max-w-md w-full h-full font-bold">
            <div className="flex flex-col justify-start items-start">
              <h2 className=" text-lg text-center mb-1">Verify your number</h2>
              <p className="text-sm mb-6">
                OTP sent to {Basic.code + " " + Basic.number}
              </p>
              <p
                onClick={() => {
                  navigate("/");
                }}
                className="text-secondary text-sm mb-6"
              >
                Edit phone number
              </p>
            </div>
            <form
              className="flex flex-col justify-between h-3/4"
              onSubmit={handleSubmit}
            >
              <div className=" flex flex-col">
                <input
                  className="pt-2 focus:outline-none bg-transparent border-b border-gray-300 w-full text-center text-2xl"
                  type="text"
                  placeholder="Enter OTP"
                  value={OTP}
                  maxLength={6}
                  required
                  onChange={(e) => setSetOTP(e.target.value)}
                />
                <div className="flex items-center justify-center mt-4 text-sm">
                  <h4 onClick={handleResendOtp} className="text-secondary">Resend OTP</h4>
                </div>
                <div id="recaptcha-container" className="mt-2"></div>
              </div>

              <button
                className={`bg-primary h-12 text-white font-bold py-2 px-4 mt-6 rounded-full w-full`}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
