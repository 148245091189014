import moment from "moment/moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetSlots, GetTimeSlots, PostApointMent } from "../utils/Api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setBasicData, setConsultData } from "../Redux/Actions/Actions";

const AppointMent = () => {
  const navigate = useNavigate();
  const Dispacth = useDispatch();
  const Basic = useSelector((state) => state.Basic);
  const Const = useSelector((state) => state.Const);
  const startTime = moment("09:00 AM", "hh:mm A"); // Start time: 9:00 AM
  const endTime = moment("06:00 PM", "hh:mm A"); // End time: 6:00 PM
  const currentDate = moment();
  const [languuage, setLanguage] = useState("english");
  const [selectedDate, setSelectedDate] = useState("");
  const [slotToShow, setSlotToShow] = useState("");
  const [type, setType] = useState("audio");
  const [date, setDates] = useState([]);
  const [time, setTime] = useState([]);
  const [slot, setSlot] = useState("");
  const [slots, setSlots] = useState([]);
  const [timeSlot, setTimeSlot] = useState("");
  const [slotToFrom, setSlotToFrom] = useState([]);
  const [finalDate, setFinalDate] = useState("");
  const [isPayement, setIsPayement] = useState(false);

  useEffect(() => {
    getSlots();
  }, [finalDate]);
  const getSlots = async () => {
    await GetTimeSlots(finalDate).then((res) => {
      res.json().then((d) => {
        setTime(d.data);
        if (Const.consultType != null) {
          setType(Const.consultType);
          setLanguage(Const.languages);
          setDates(Const.date);
          setTime(Const._timeArrey);
          setTimeSlot(Const._timeSlot)
          setSlots(Const._slotArrey)
          setSlot(Const._slot)
          setSlotToShow(Const.slotToShow)
          setSlotToFrom(Const.slotToFrom)
        }
      });
    });

   
  };
  useLayoutEffect(() => {
    let currentTime = startTime.clone();
    let temp = [];
    let tempSlots = [];
    for (let i = 0; i < 15; i++) {
      const nextDate = currentDate.clone().add(i, "days");
      const dayName = nextDate.format("dddd");
      const date = nextDate.format("D");
      temp.push({
        id: i,
        date,
        day: dayName,
        finaldate: nextDate.format("yyyy-MM-DD"),
        isActive: false,
      });
    }
    let id = 0;
    while (currentTime.isBefore(endTime)) {
      const fromTime = currentTime.format("hh:mm A");
      currentTime.add(30, "minutes");
      const toTime = currentTime.format("hh:mm A");
      tempSlots.push({
        id: id,
        time: `${fromTime} to ${toTime}`,
        isActive: false,
      });
      id++;
    }
    setTime(tempSlots);
    setDates(temp);
  }, []);

  useLayoutEffect(() => {
   GetAponitmentSlot()
  }, [type, timeSlot]);

  const GetAponitmentSlot=async()=>{
    await GetSlots(type, timeSlot,finalDate).then((d) =>
      d.json().then((data) => {
        setSlots(data.data);
      })
  );
  }

  const GetIn24 = (time) => {
    return moment(time, "hh:mm A").format("HH:mm");
  };
  const handleContinue = () => {
    if (finalDate == "") {
      toast.warning("Please select a date.");
      return;
    }
    if (slot == "") {
      toast.warning("Please select a slot.");
      return;
    }
    const normalHeader={
      'Content-Type':'application/json',
      device:"Android",
      Accept:"application/json",
      CorrelationId:1234551516516565,
      AuthToken:Basic.authToken,
      userId:Basic.id
  }
    let data = {
      guestId: Basic.id,
      consultType: type,
      consultationDate: finalDate,
      slot: slot,
      FromTime: GetIn24(slotToFrom[0]),
      ToTime: GetIn24(slotToFrom[1]),
      languages: languuage,
      userType: Basic.type,
      DoctorId: "-1",
      helpQuestion1: Basic.qs,
      AppointmentReqID:Basic.appReqId
    };
    let forCaching = {
      consultType: type,
      date: date,
      finalDate,
      languages: languuage,
      slotToShow:slotToShow,
      _timeSlot: timeSlot,
      _timeArrey:time,
      _slot: slot,
      _slotArrey: slots,
      slotToShow,
      slotToFrom,
    
    };
    Dispacth(setConsultData(forCaching));
    PostApointMent(normalHeader,data).then((r) =>
      r.json().then((d) => {
        if (d.status) {
          let tempBasic = Basic;
          tempBasic.appReqId = d.appReqId;
          Dispacth(setBasicData(tempBasic));
          setIsPayement(true);
        }
      })
    );
  };
  const HandlePayment = () => {
    navigate("/payement-page");
  };
  const handlelanguage = (value) => {
    setLanguage(value);
  };
  const handleDate = (value) => {
    let temp = [];
    setFinalDate(value.finaldate);
    setTimeSlot("")
    setSlot("");
    setSlotToShow("");
    setSlotToFrom([]);
    setIsPayement(false)
    Dispacth(setConsultData({}))
    for (let i = 0; i < date.length; i++) {
      const d = date[i];
      if (value.id == d.id) {
        setSelectedDate(d);
        d.isActive = true;
        temp.push(d);
      } else {
        d.isActive = false;
        temp.push(d);
      }
    }

    setDates(temp);
  };
  const handleType = (value) => {
    setType(value);
  };
  const handleSlot = (value, toFrom, index) => {
    setSlot(value);
    setSlotToShow(toFrom);
    let toFroms = toFrom.split("-");
    setSlotToFrom(toFroms);
  };
  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      {/* <h1 className="text-2xl font-bold mb-4">
        Take a doctor consultation call
      </h1>
      <p>Doctors are available within the next few minutes</p> */}
      <div className="h-3/4 flex flex-col justify-between w-full">
        <div>
          <div>
            <h1 className=" text-sm mt-0">Select language</h1>
            <div className="flex mt-2">
              <div
                onClick={() => {
                  handlelanguage("english");
                }}
                className={`rounded-full ${
                  languuage == "english"
                    ? "bg-primary text-white"
                    : "bg-gray-200 border border-gray-300 text-gray-400"
                } px-8 py-1 cursor-pointer`}
              >
                <h1 className=" text-sm">English</h1>
              </div>
              <div
                onClick={() => {
                  handlelanguage("hindi");
                }}
                className={`rounded-full ${
                  languuage == "hindi"
                    ? "bg-primary text-white"
                    : "bg-gray-200 border border-gray-300  text-gray-400"
                } px-8 py-1 ml-4 cursor-pointer`}
              >
                <h1 className=" text-sm">Hindi</h1>
              </div>
            </div>
            <h1 className="text-sm mt-4">Select Date</h1>
            <div className="flex overflow-scroll ">
              {date.map((value, index) => (
                <DateModal
                  key={index}
                  value={value}
                  handleDate={handleDate}
                  Date={date}
                  Const={Const}
                />
              ))}
            </div>
            <h1 className="text-sm mt-4">Select Time</h1>
            <div className="flex w-full overflow-scroll">
              {time.map((value, index) => (
                <TimeModal
                  key={index}
                  value={value}
                  setTimeSlot={setTimeSlot}
                  Times={time}
                  timeSlot={timeSlot}
                />
              ))}
            </div>
            <h1 className="text-sm mt-4">Select type</h1>
            <div className="flex mt-2">
              <div
                onClick={() => {
                  handleType("audio");
                }}
                className={`rounded-full ${
                  type == "audio"
                    ? "bg-primary text-white"
                    : "bg-gray-200 border border-gray-300 text-gray-400"
                } px-8 py-1 cursor-pointer`}
              >
                <h1 className=" text-sm">Audio</h1>
              </div>
              <div
                onClick={() => {
                  handleType("video");
                }}
                className={`rounded-full ${
                  type == "video"
                    ? "bg-primary text-white"
                    : "bg-gray-200 border border-gray-300 text-gray-400"
                } px-8 py-1 ml-4 cursor-pointer`}
              >
                <h1 className=" text-sm">Video</h1>
              </div>
            </div>
            <h1 className="text-sm mt-4">Appointment slot</h1>
            <div className="flex w-full overflow-scroll">
              {slots.map((value, index) => {
                return (
                  <div
                    onClick={() => {
                      handleSlot(value.slot, value.slotDesc, index);
                    }}
                    key={index}
                    className={`${
                      value.slot == slot
                        ? "bg-primary text-white"
                        : "bg-gray-200 text-gray-400 border border-gray-300"
                    } rounded-2xl flex flex-col items-center justify-center py-2 px-2 mr-2 mt-2 cursor-pointer `}
                  >
                    <h1 className="text-sm text-center w-28">
                      {value.slotDesc}
                    </h1>
                  </div>
                );
              })}
            </div>
            <div className="w-full bg-gray-200 border border-gray-300 pt-4 mt-6 rounded-2xl">
              <div className="flex px-2">
                <img src="./assets/telephone.svg" />
                <h1 className="text-sm ml-2">
                  Consultation will be done on phone call
                </h1>
              </div>
              <div className="flex mt-1 px-2">
                <img src="./assets/stopwatch.svg" />
                <h1 className="text-sm ml-2">
                  Phone call will be last for 10-15 mins
                </h1>
              </div>
              <div className="flex mt-1 px-2">
                <img src="./assets/verify.svg" />
                <h1 className="text-sm ml-2">
                  Call will start any time between selected slots
                </h1>
              </div>
              <div className="flex mt-1 bg-thBlue rounded-2xl p-1 mt-3">
                <h1 className="text-sm text-white ml-2">
                  Get 10% off on all medicines
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="flex pr-8">
          <button
            className=" bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            style={{ display: isPayement ? "none" : "flex" }}
            onClick={handleContinue}
          >
            Confirm
          </button>
          <button
            className="bg-primary h-12 flex items-center justify-center text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            style={{ display: !isPayement ? "none" : "flex" }}
            onClick={HandlePayment}
          >
            Make Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointMent;

function DateModal({ value, handleDate, Date ,Const}) {
  useEffect(() => {
    if(Const.consultationDate==null){
      handleDate(Date[0]);
    }
  }, []);
  return (
    <div
      onClick={() => {
        handleDate(value);
      }}
      className={`${
        value.isActive
          ? "bg-primary text-white"
          : "bg-gray-200 text-gray-400 border border-gray-300"
      } rounded-2xl flex flex-col items-center justify-center w-20 h-20 py-4 px-6 mr-2 mt-2 cursor-pointer`}
    >
      <h1 className="text-sm">{value.day.substring(0, 3).toUpperCase()}</h1>
      <h1 className="text-2xl">{value.date}</h1>
    </div>
  );
}
function TimeModal({ value, setTimeSlot, Times, timeSlot }) {
  useEffect(() => {
    setTimeSlot(Times[0].SlotDesc);
  }, []);
  return (
    <div
      onClick={() => {
        setTimeSlot(value.SlotDesc);
      }}
      className={`${
        value.SlotDesc == timeSlot
          ? "bg-primary text-white"
          : "bg-gray-200 text-gray-400 border border-gray-300 w-full"
      } rounded-2xl  flex flex-col items-center justify-center py-2 px-2 mr-2 mt-2 cursor-pointer`}
    >
      <h1 className="text-sm text-center w-28">{value.Slot}</h1>
    </div>
  );
}
