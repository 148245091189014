// components/ChildHelpPage.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../Redux/Actions/Actions";
import { useLayoutEffect } from "react";
import { toast } from "react-toastify";
import { HandlePostBasic, HandlePostGeust, HandlePostUserRelation } from "../../utils/Api";

const ChildAgePage = () => {
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.User);
  const Basic = useSelector((state) => state.Basic);
  const Dispatch = useDispatch();
  const questions = ["0-2", "2-6", "7-12", "13-18"];

  const [selectedOptions, setSelectedOptions] = useState(new Array());
  const [selectedOption, setSelectedOption] = useState("");


  useLayoutEffect(()=>{
    if(UserData.age!=null){
      setSelectedOption(UserData.age)
    }
  },[])


  const handleOptionChange = (option) => {
    let temp = [questions.length];
    setSelectedOption(questions[option]);
    if (selectedOptions.length > 0) {
      for (let i = 0; i < selectedOptions.length; i++) {
        const element = selectedOptions[i];
        if (element != option) {
          temp[option] = option;
        }
      }
      setSelectedOptions(temp);
    } else {
      temp[option] = option;
      setSelectedOptions(temp);
    }
  };

  const handleContinue = () => {
    if (selectedOption != "") {
      let temp = UserData;

      temp.age = selectedOption;
      let headers = {
        "Content-Type": "application/json",
        device: "Android",
        Accept: "application/json",
        CorrelationId: 1234551516516565,
        AuthToken: Basic.authToken,
        userId: Basic.id,
      };
      HandlePostBasic(headers,temp)
      Dispatch(setUserData(temp));
      navigate("/child-gender");
    }else{
      toast.warn("Please select age to continue.")
    }
  };

  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      <h1 className="text-2xl font-bold mb-4">What is your child's age?</h1>
      <p>Answer question(s) about your child and our doctor will call in 5 minutes</p>
      <div className="h-3/4 flex flex-col justify-between">
        <div className="mt-8">
          {questions.map((question, index) => (
            <div
              key={index}
              className="mb-4 p-4 flex items-center rounded-xl bg-gray-100 border border-gray-200 w-10/12"
              // onClick={}
            >
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={`question_${index}`}
                  value={index}
                  checked={question == selectedOption}
                  onChange={() => handleOptionChange(index)}
                  className=" appearance-none w-8 h-8 border-2 border-primary rounded-full bg-transparent
                mt-1 shrink-0
                checked:bg-primary checked:border-0"
                />
              </label>
              <p className="ml-5 text-sm font-semibold mb-2">{question}</p>
            </div>
          ))}
        </div>
        <div className="flex">
          <button
            className=" bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChildAgePage;
