import React, { useRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { GetVactinationPlan, normalHeader } from "../../../utils/Api";
import SliderComponent from "../SliderComponent";
import { AiOutlineTrophy, AiOutlineFilter } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { BiInjection } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setLoading, setMileStone } from "../../../Redux/Actions/Actions";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TotpMultiFactorGenerator } from "firebase/auth";

const CategoriesImageArrey = [
  {
    category: "Birth",
    img: "/assets/vacination/birth.svg",
  },
  {
    category: "6 weeks",
    img: "/assets/vacination/six-week.svg",
  },
  {
    category: "10 weeks",
    img: "/assets/vacination/ten-week.svg",
  },
  {
    category: "14Weeks",
    img: "/assets/vacination/14-week.svg",
  },
  {
    category: "6 Months",
    img: "/assets/vacination/six-month.svg",
  },
  {
    category: "7 Months",
    img: "/assets/vacination/7-month.svg",
  },
  {
    category: "9 Months",
    img: "/assets/vacination/9-month.svg",
  },
  {
    category: "18 – 24 Months",
    img: "/assets/vacination/18-24-month.svg",
  },
  {
    category: "18 Months",
    img: "/assets/vacination/18-month.svg",
  },
  {
    category: "12 Months",
    img: "/assets/vacination/12-month.svg",
  },
  {
    category: "13 Months",
    img: "/assets/vacination/13-month.svg",
  },
  {
    category: "15 – 18 Months",
    img: "/assets/vacination/15to18-month.svg",
  },
  {
    category: "15 Months",
    img: "/assets/vacination/15-month.svg",
  },
  {
    category: "24 Months",
    img: "/assets/vacination/24-month.svg",
  },
  {
    category: "3 Years",
    img: "/assets/vacination/3-years.svg",
  },
  {
    category: "4 Years",
    img: "/assets/vacination/4years.svg",
  },
  {
    category: "4-6 Years",
    img: "/assets/vacination/4-6-years.svg",
  },
  {
    category: "5 Years",
    img: "/assets/vacination/5-years.svg",
  },
  {
    category: "9 – 14 Years",
    img: "/assets/vacination/9-14-year.svg",
  },
  {
    category: "15 - 18 Years",
    img: "/assets/vacination/15-years.svg",
  },
];

export default function Vactination() {
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("AuthToken").replace(" ", "+");
  const UserType = queryParameters.get("UserType");
  const UserId = parseInt(queryParameters.get("UserId"));
  const ref = useRef(null);

  const [data, setData] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [FilteredData, setFilteredData] = useState();
  const [vacFilter, setVacFilter] = useState("all");
  const scrollableDivRef = useRef(null);
  const [selectedAge, setSelectedAge] = useState();
  const [scrollID, setScrollID] = useState("");
  const [isAgeSelected, setAgeSelected] = useState(0);
  const Dispatch = useDispatch();
  useLayoutEffect(() => {
    let header = {
      ...normalHeader,
      AuthToken,
      userId: UserId,
    };
    GetVactinationPlan(UserId, UserType, header).then((res) => {
      if (res.status) {
        let data = res.data;
        let cates = res.categories;
        let scrollTo = res.patientCategory;
        setScrollID(scrollTo);
        setSelectedAge(cates[0]);
        setTabData(cates);
        setData(data);
      }
    });
  }, []);

  useEffect(() => {
    if (vacFilter == "all") {
      let tempd = [];
      tabData.forEach((element) => {
        let FilterdData = data.filter(
          (data) => data.DueTime == element.category
        );
        let values = {
          name: element.category,
          data: FilterdData,
        };
        tempd.push(values);
      });
      setDataToRender(tempd);
    }
    if (vacFilter == "overdue") {
      let filter = data.filter((data) => data.DueStatus == "Overdue");
      let tempd = [];
      tabData.forEach((element) => {
        let FilterdData = filter.filter(
          (data) => data.DueTime == element.category
        );
        let values = {
          name: element.category,
          data: FilterdData,
        };
        tempd.push(values);
      });
      setDataToRender(tempd);
    }
    if (vacFilter == "upcoming") {
      let filter = data.filter((data) => data.DueStatus == "Upcoming");
      let tempd = [];
      tabData.forEach((element) => {
        let FilterdData = filter.filter(
          (data) => data.DueTime == element.category
        );
        let values = {
          name: element.category,
          data: FilterdData,
        };
        tempd.push(values);
      });
      setDataToRender(tempd);
    }
    if (vacFilter == "achived") {
      let filter = data.filter((data) => data.DueStatus == "Taken");
      let tempd = [];
      tabData.forEach((element) => {
        let FilterdData = filter.filter(
          (data) => data.DueTime == element.category
        );
        let values = {
          name: element.category,
          data: FilterdData,
        };
        tempd.push(values);
      });
      setDataToRender(tempd);
    }
  }, [vacFilter, data]);

  useEffect(() => {
    if (tabData.length > 0) {
      tabData.forEach((data, index) => {
        if (data.category == scrollID) {
          scrollToTab(data, index);
        }
      });
    }
  }, [scrollID, tabData]);

  const handleAgeClick = (data, index) => {
    setAgeSelected(index);
    scroll(index);
    setSelectedAge(data);
    // Dispatch(setLoading({ loading: true, otp: false }));
  };
  const scroll = (id) => {
    const section = document.querySelector(".test-" + id);
    if (section != null) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const scrollToTab = (data, index) => {
    const targetElement = document.getElementById(data.category);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        handleAgeClick(data, index);
      }, 1000);
      // const position = targetElement.offsetLeft - 432;
      // scrollableDivRef.current.scrollLeft = position;
    }
  };

  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light">
      <div className="flex items-center justify-between px-4 mt-2">
        <div></div>
        <div className="flex items-center space-x-2">
          <select
            className=" bg-transparent outline-none text-blue-dark"
            value={vacFilter}
            onChange={(e) => setVacFilter(e.target.value)}
          >
            <option value={"overdue"}>Overdue Vaccination</option>
            <option value={"upcoming"}>Upcoming Vaccination</option>
            <option value={"achived"}>Done Vaccination</option>
            <option value={"all"}>Show All Vaccination</option>
          </select>
          <AiOutlineFilter size={25} className=" text-blue-dark" />
        </div>
      </div>
      <div className="p-4 w-full">
        <SliderComponent type={"Vaccination"} />
      </div>
      {data.length > 0 ? (
        <div>
          <div
            className="w-full px-4 py-2 flex items-center space-x-2 overflow-scroll overflow-y-hidden "
            ref={scrollableDivRef}
          >
            {tabData.map((data, index) => (
              <div
                id={data.category}
                onClick={() => handleAgeClick(data, index)}
                key={index}
                className={`w-max h-12 border px-4  border-black rounded-lg  flex flex-col  items-center justify-center ${
                  isAgeSelected == index
                    ? "text-white bg-orange-v2"
                    : "bg-white text-thtext"
                }`}
              >
                {/* {data.age != 0 && <h2 className="text-sm">{data.age}</h2>} */}
                <h2 className="text-sm w-max">{data.category}</h2>
              </div>
            ))}
          </div>
          <div className="overflow-scroll h-3/4 overflow-x-hidden">
            {dataToRender.map((data, index) => (
              <MainCardView data={data} index={index} key={index} />
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full my-10 flex items-center justify-center">
          <h4 className="">No data</h4>
        </div>
      )}
    </div>
  );
}

const MainCardView = ({ data, index }) => {
  const [Image, setImage] = useState("");
  let key = data.name.toLowerCase();
  key = key.replace(" ", "");
  useEffect(() => {
    if (data != null) {
      let image = CategoriesImageArrey.filter(
        (d) => d.category.toLowerCase() == data.name.toLowerCase()
      );
      setImage(image[0].img);
    }
  }, [data]);

  return (
    <div className={"test-" + index}>
      <div className="bg-blue-dark h-28 flex items-center justify-between px-4">
        <div className="w-full flex items-center justify-start">
          <img className="mt-2 w-24 h-24" alt="age-image" src={Image} />
        </div>
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col items-center justify-between py-2">
            <h2 className="text-2xl text-white">{data.name}</h2>

            <div className="flex items-center justify-between w-full mt-2">
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                <h2 className="text-xs text-white">Overdue</h2>
              </div>
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
                <h2 className="text-xs text-white">Upcoming</h2>
              </div>
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                <h2 className="text-xs text-white">Done</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 space-y-2">
        {data.data.map((data, index) => (
          <SubCardView data={data} key={index} />
        ))}
      </div>
    </div>
  );
};

const SubCardView = ({ data }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("AuthToken").replace(" ", "+");
  const UserType = queryParameters.get("UserType");
  const UserId = parseInt(queryParameters.get("UserId"));
  const [date, setDate] = useState("");
  const [isViewEnable, setViewEnable] = useState(false);
  const router = useNavigate();
  const Dispatch = useDispatch();

  useEffect(() => {
    if (data.DueStatus == "Taken") {
      let date = "Taken on " + GetDate(data.TakenOn);
      setDate(date);
    }
    if (data.DueStatus == "Upcoming") {
      let date = "Due on " + GetDate(data.DueOn);
      setDate(date);
    }
    if (data.DueStatus == "Overdue") {
      let date = "Due on " + GetDate(data.DueOn);
      setDate(date);
    }
  }, []);
  const GetDate = (date) => {
    return moment(date).format("DD-MMM-YYYY");
  };

  const handleView = () => {
    setViewEnable((pre) => !pre);
  };
  const handleDetailsClick = () => {
    if (UserType != "Guest") {
      Dispatch(
        setMileStone({
          UserId,
          AuthToken,
          data,
        })
      );
      router("form");
    } else {
      toast.warn("Register with us for tracking vaccinations of your child");
    }
  };
  return (
    <div className="bg-white rounded-lg">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={handleView}
      >
        <div className="p-2 flex items-center space-x-2">
          <div>
            <BiInjection
              size={22}
              className={`${
                data.DueStatus == "Overdue"
                  ? "text-red-500"
                  : data.DueStatus == "Upcoming"
                  ? "text-yellow-500"
                  : data.DueStatus == "Taken"
                  ? "text-green-500"
                  : ""
              }`}
            />
          </div>
          <div className="flex items-center space-x-1 text-sm">
            <h3>{data.VaccineName}</h3>
            <h3>{data.Dose}</h3>
          </div>
        </div>
        <div className="flex items-center space-x-2 px-2">
          <div className="text-sm">
            <h2>{date}</h2>
          </div>
          <div className="text-gray-600">
            <IoIosArrowDown size={20} />
          </div>
        </div>
      </div>
      {isViewEnable && (
        <div className="w-full h-max py-2 ">
          <div className="w-full px-2 grid grid-cols-8 gap-2">
            <div className=" col-span-3 font-bold text-black">
              <h2>Due on</h2>
            </div>
            <div className=" col-span-5 text-black flex items-center justify-between">
              <h3>{GetDate(data.DueOn)}</h3>
              <button
                className="text-sm border border-blue-dark rounded-lg px-2 py-1"
                onClick={handleDetailsClick}
              >
                View Details
              </button>
            </div>
            <div className=" col-span-3 font-bold text-black">
              <h2>Taken on</h2>
            </div>
            <div className=" col-span-5 text-black">
              <h3>{data.TakenOn != "" ? GetDate(data.TakenOn) : ""}</h3>
            </div>
            <div className=" col-span-3 font-bold text-black">
              <h2>Dose</h2>
            </div>
            <div className=" col-span-5 text-black">
              <h3>{data.strDose}</h3>
            </div>
            <div className=" col-span-3 font-bold text-black">
              <h2>Protected Against</h2>
            </div>
            <div className=" col-span-5 text-black">
              <h3>{data.ProtectAgainst}</h3>
            </div>
            <div className=" col-span-3 font-bold text-black">
              <h2>To Be Given</h2>
            </div>
            <div className=" col-span-5 text-black">
              <h3>{data.ToBeGiven}</h3>
            </div>
            <div className=" col-span-3 font-bold text-black">
              <h2>Important Info</h2>
            </div>
            <div className=" col-span-5 text-black">
              <h3>{data.ImportantInfo}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
