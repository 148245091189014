import { combineReducers } from 'redux';
import handleTheme from './Theme';
import handleLoading from './Loading';
import User from './UserData';
import Basic from './Basic';
import Otp from './OtpConfirmation';
import MainUser from './Mainuser';
import VideoCall from './VideoCall';
import Temp from './TempData';
import Const from './ConsultValuesSaver';
import Milestone from './MileStone';

const rootReducer = combineReducers({
  theme: handleTheme,
  loading:handleLoading,
  User,
  Basic,
  Otp,
  MainUser,
  VideoCall,
  Temp,
  Const,
  Milestone
});

export default rootReducer;