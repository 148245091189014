import { ADD_TO_CART, GET_ALL_SLOTS, GET_ALL_TIME_SLOTS, GET_DIET_PLAN, GET_GOAL_LIST, GET_GROWTH_TRACKER, GET_PAYMENT_DETAILS, GET_PLAN_BY_ID, GET_VACTINATION_PLAN, GET_VIDEO_CALL_LOGS, GROWTH_DELETE, GetAllRetaion, GetDataByNumber, GetFamilyData, HANDLE_AUDIO_CALL, HANDLE_GUEST_ADD, POST_APPOINTMENT_INFO, POST_GOAL_FORM, POST_GOAL_STATUS, POST_GROWTH_DATA, POST_VACTINATION_STATUS, PostBasicData, SLIDER, UPDATE_CART_STATUS } from "./utils"

export const normalHeader={
    'Content-Type':'application/json',
    device:"Android",
    Accept:"application/json",
    CorrelationId:1234551516516565,
    AuthToken:"wwKh313J2UjRsobLLrwHT5kqfxMVj1Cw",
    userId:"270"
}

export const HandleNumber=(data)=>{
    return fetch(GetDataByNumber,{
        method:"POST",
        headers:normalHeader,
        body:JSON.stringify(data)
    })
}
export const HandlePostBasic=(header,data)=>{
    return fetch(PostBasicData,{
        method:"POST",
        headers:header,
        body:JSON.stringify(data)
    })
}
export const HandlePostGeust=(data)=>{
    return fetch(HANDLE_GUEST_ADD,{
        method:"POST",
        headers:normalHeader,
        body:JSON.stringify(data)
    })
}
export const HandlePostUserRelation=(headers,data)=>{
    return fetch(HANDLE_GUEST_ADD,{
        method:"POST",
        headers:headers,
        body:JSON.stringify(data)
    })
}
export const HandleFamily=(id,type)=>{
    return fetch(GetFamilyData+"?UserId="+id+"&UserType="+type,{
        method:"GET",
        headers:normalHeader,
    })
}

export const GetAllRetaionData=()=>{
    return fetch(GetAllRetaion,{
        method:"GET",
        headers:normalHeader,
        // body:JSON.stringify(data)
    })
}
export const PostApointMent=(headers,data)=>{
    return fetch(POST_APPOINTMENT_INFO,{
        method:"POST",
        headers:headers,
        body:JSON.stringify(data)
    })
}
export const GetSlots=(type,time,date)=>{
    return fetch(GET_ALL_SLOTS+"?type="+type+"&doctorId=-1"+"&desc="+time+`&dt=${date}`,{
        method:"GET",
        headers:normalHeader,
    })
}
export const GetTimeSlots=(date)=>{
    return fetch(GET_ALL_TIME_SLOTS+`?dt=${date}`,{
        method:"GET",
        headers:normalHeader,
    })
}
//plans
export const GetPlanById=(headers,id,userType,userId)=>{
    return fetch(GET_PLAN_BY_ID+`?id=${id}&userType=${userType}&userId=${userId}&productType=CONSULT`,{
        method:"GET",
        headers:headers,
    }).then(res=>{
        return res.json();
    })
}

export const AddToCart=(head,data)=>{
    return fetch(ADD_TO_CART,{
        method:"POST",
        headers:head,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}
export const UpdateCartStatus=(head,data)=>{
    return fetch(UPDATE_CART_STATUS,{
        method:"POST",
        headers:head,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}

//calling and payment info

export const InitAudioCall=(data)=>{
    return fetch(HANDLE_AUDIO_CALL,{
        method:"POST",
        headers:normalHeader,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}
export const GetPaymentInfo=(Head,data)=>{
    return fetch(GET_PAYMENT_DETAILS+`?userType=${data.userType}&userId=${data.userId}&uniqueId=${data.uniqueId}`,{
        method:"GET",
        headers:Head,
    }).then(res=>{
        return res.json();
    })
}
export const GetVideoLog=(id,type)=>{
    return fetch(GET_VIDEO_CALL_LOGS+`?id=${id}&userType=${type}`,{
        method:"GET",
        headers:normalHeader,
    }).then(res=>{
        return res.json();
    })
}

//Milestone
export const GetMileStoneData=(id,from,to,planid,UserType,header)=>{
    return fetch(GET_GOAL_LIST+`?patientId=${id}&limit=-1&fromMonth=${from}&toMonth=${to}&planId=${planid}&userType=${UserType}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
}
export const PostMileStoneStatus=(data,header)=>{
    return fetch(POST_GOAL_STATUS,{
        method:"POST",
        headers:header!=null?header:normalHeader,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}
export const PostMileStoneVideo=(data,header)=>{
    return fetch(POST_GOAL_FORM,{
        method:"POST",
        headers:header!=null?header:normalHeader,
        body:data
    }).then(res=>{
        return res.json();
    })
}
//Growth
export const GetGrowthTracker=(id,param,UserType,header)=>{
    return fetch(GET_GROWTH_TRACKER+`?patientId=${id}&limit=-1&param=${param}&userType=${UserType}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
}
export const AddGrowthData=(data,header)=>{
    return fetch(POST_GROWTH_DATA,{
        method:"POST",
        headers:header!=null?header:normalHeader,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}
export const DeleteGrowthData=(data,header)=>{
    return fetch(GROWTH_DELETE,{
        method:"POST",
        headers:header!=null?header:normalHeader,
        body:JSON.stringify(data)
    }).then(res=>{
        return res.json();
    })
}
//diet
export const GetDietPlan=(id,UserType,header)=>{
    return fetch(GET_DIET_PLAN+`?patientId=${id}&userType=${UserType}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
}
//vactination
export const GetVactinationPlan=(id,userType,header)=>{
    return fetch(GET_VACTINATION_PLAN+`?patientId=${id}&userType=${userType}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
}
export const PostVactinationVid=(data,header)=>{
    return fetch(POST_VACTINATION_STATUS,{
        method:"POST",
        headers:header!=null?header:normalHeader,
        body:data
    }).then(res=>{
        return res.json();
    })
}

//slider
export const GetSliderData=(id,type,header)=>{
   if(id!=null){
    return fetch(SLIDER+`?patientId=${id}&requestType=${type}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
   }else{
    return fetch(SLIDER+`?patientId=-1&requestType=${type}`,{
        method:"GET",
        headers:header!=null?header:normalHeader,
    }).then(res=>{
        return res.json();
    })
   }
}
