import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Suspense, useEffect, useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginDetails from "./pages/Login/LoginDetails";
import EnterNumber from "./pages/Login/EnterNumber";
import EnterOtp from "./pages/Login/Otp";
import ChildHelpPage from "./pages/Childpages/ChildHelp";
import ChildAgePage from "./pages/Childpages/ChildsAge";
import ChildGenderPage from "./pages/Childpages/Childsgender";
import DoctorConsultation from "./pages/DoctorCons";
import AppointMent from "./pages/Apointment";
import Confirmed from "./pages/ApointedSuccess";
import Loader from "./components/Loader";
import OnCall from "./pages/Oncall";
import Prescription from "./pages/Prescriptions";
import LoadingModal from "./components/LoadingModal";
import PayementPage from "./pages/Payement";
import VideoCall from "./pages/VideoCall";
import LayoutMilestone from "./pages/milestone-and-growth/layout";
import Milestone from "./pages/milestone-and-growth/milestone/Milestone";
import MileStoneFarmPage from "./pages/milestone-and-growth/milestone/MileStoneFarm";
import GrowthMainPage from "./pages/milestone-and-growth/growth/GrowthMainPage";
import Diet from "./pages/milestone-and-growth/diet/Diet";
import Vactination from "./pages/milestone-and-growth/vactnation/Vactination";
import VactinationFarm from "./pages/milestone-and-growth/vactnation/VactinationFarm";

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />} />
      <Provider store={store}>
        <LoadingModal />
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<EnterNumber />} />
          <Route exact path="/details" element={<LoginDetails />} />
          <Route exact path="/phone-otp" element={<EnterOtp />} />
          <Route exact path="/child-help" element={<ChildHelpPage />} />
          <Route exact path="/child-age" element={<ChildAgePage />} />
          <Route exact path="/child-gender" element={<ChildGenderPage />} />
          <Route exact path="/doctor-cons" element={<DoctorConsultation />} />
          <Route exact path="/doctor-apointment" element={<AppointMent />} />
          <Route exact path="/payement-page" element={<PayementPage />} />
          <Route exact path="/doctor-confirmed" element={<Confirmed />} />
          <Route exact path="/doctor-oncall" element={<OnCall />} />
          <Route exact path="/prescriptions" element={<Prescription />} />
          <Route exact path="/videocall" element={<VideoCall />} />
          <Route
            exact
            path={`/milestone-and-growth/*`}
            element={<LayoutMilestone />}
          >
            <Route exact path="milestone" element={<Milestone />} />
            <Route exact path="milestone/form" element={<MileStoneFarmPage/>} />
            <Route exact path="growth" element={<GrowthMainPage/>} />
            <Route exact path="diet" element={<Diet/>} />
            <Route exact path="vaccination" element={<Vactination/>} />
            <Route exact path="vaccination/form" element={<VactinationFarm/>} />
          </Route>
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
