import React, { useLayoutEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GetSliderData } from "../../utils/Api";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function SliderComponent({type}) {
  const [data, setData] = useState([]);

  useLayoutEffect(() => {
    GetSliderData(null,type).then((res) => {
      if (res.status) {
        setData(res.Banners);
      }
    });
  }, []);
  return (
    <div className="w-full h-28  bg-white rounded-lg">
      <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={1000}>
        {data.map((data, index) => (
          <div key={index} className="w-full h-28">
            <img
              alt="crousal-image"
              src={data.imageURL}
              className="w-full h-full  rounded-lg"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
