// components/ChildHelpPage.js
import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../Redux/Actions/Actions";
import { useLayoutEffect } from "react";
import { toast } from "react-toastify";
import {
  HandlePostBasic,
  HandlePostGeust,
  HandlePostUserRelation,
} from "../../utils/Api";
import moment from "moment/moment";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css'
const ChildGenderPage = () => {
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.User);
  const Basic = useSelector((state) => state.Basic);
  const Dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(0);
  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState(0);
  const [age, setAge] = useState("");
  const [isPremature, setIsPremature] = useState(false);

  useLayoutEffect(() => {
    if (UserData.gender != null) {
      setGender(UserData.gender);
      setWeight(UserData.weight);
      setSliderValue(UserData.height);
      setAge(UserData.dob);
      setIsPremature(UserData.isPremature);
    }
  }, []);

  const handleContinue = () => {
    if (sliderValue == 0 || gender == "" || weight == 0) {
      toast.warn("Please fill all the details..");
      return;
    }
    if (age == "" && isPremature == false) {
      toast.warn("Please fill all the details..");
      return;
    }
    let date = moment(age).format("yyyy-MM-DD");
    let data = UserData;
    data.gender = gender;
    data.height = sliderValue;
    data.weight = weight;
    data.dob = date;
    data.isPremature = isPremature;
    let headers = {
      "Content-Type": "application/json",
      device: "Android",
      Accept: "application/json",
      CorrelationId: 1234551516516565,
      AuthToken: Basic.authToken,
      userId: Basic.id,
    };
    console.log(data);
    HandlePostBasic(headers, data).then(res=>{
      res.json().then(res=>{
        console.log(res);
      })
    });
    data.dob=age
    Dispatch(setUserData(data));
    navigate("/child-help");
  };

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      <h1 className="text-2xl font-bold mb-4">
        What is your child's gender, height and weight?
      </h1>
      <p>
        Answer question(s) about your child and our doctor will call in 5
        minutes
      </p>
      <div className="h-3/4 flex flex-col justify-between">
        <div>
          <div className="my-2">
            <h2>Date of birth</h2>
            <div className="w-full flex flex-col items-center justify-center">
               <DatePicker format="dd/MM/yyyy" required maxDate={new Date()} value={age} onChange={setAge} className="w-full bg-transparent rounded-2xl" />
              <div className="flex items-center space-x-2">
                <input type="checkbox" className="mt-2" checked={isPremature} onChange={e=>setIsPremature(pre=>!pre)} />
                <h3 className="text-sm mt-2">Born at less then 35 weeks ago?</h3>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div
              onClick={() => {
                setGender("Male");
              }}
              className={`${
                gender == "Male" ? "bg-accent" : "bg-gray-100"
              } border border-gray-200 flex flex-col items-center justify-center h-40 rounded-2xl mt-2`}
            >
              <img src="./assets/boy1.svg" />
              <h1>Male</h1>
            </div>
            <div
              onClick={() => {
                setGender("Female");
              }}
              className={`${
                gender == "Female" ? "bg-accent" : "bg-gray-100"
              }  border border-gray-200 flex flex-col items-center justify-center h-40 rounded-2xl mt-2`}
            >
              <img src="./assets/girl1.svg" />
              <h1>Female</h1>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-sm mt-4">HEIGHT</h1>
            <p className="text-2xl mt-5">{sliderValue} cms</p>
            <input
              type="range"
              min="0"
              max="250"
              value={sliderValue}
              onChange={handleSliderChange}
              className=" w-full h-3"
            />
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-sm mt-4">WEIGHT</h1>
            <div className="p-4 bg-gray-200 border border-gray-400 rounded-xl mt-4 w-48 h-36">
              <div className="p-2 bg-gray-400  rounded-xl w-full h-3/5 shadow-inner flex items-center justify-center">
                <div className="flex items-end justify-center text-3xl w-1/2 ml-8">
                  <input
                    type="text"
                    maxLength={4}
                    value={weight}
                    onChange={(e) =>
                      setWeight(e.target.value.replace(/[^.0-9]/g, ""))
                    }
                    className="w-16 outline-none bg-transparent"
                  />
                  <span className="text-sm ml-0">kgs</span>
                </div>
              </div>
              <div className=" flex justify-between p-3">
                <div
                  onClick={() => {
                    if (weight > 0) {
                      setWeight(parseInt(weight) - 1);
                    }
                  }}
                  className="rounded-full bg-accent w-8 h-8 flex items-center justify-center shadow-lg"
                >
                  <AiOutlineMinus className="text-white" />
                </div>
                <div
                  onClick={() => setWeight(parseInt(weight) + 1)}
                  className="rounded-full bg-accent w-8 h-8 flex items-center justify-center shadow-lg"
                >
                  <AiOutlinePlus className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <button
            className=" bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChildGenderPage;
