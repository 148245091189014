import React, { useEffect } from "react";
import SliderComponent from "../SliderComponent";
import { useState } from "react";
import { useLayoutEffect } from "react";
import {
  DeleteGrowthData,
  GetGrowthTracker,
  normalHeader,
} from "../../../utils/Api";
import { useDispatch } from "react-redux";
import { setMileStone } from "../../../Redux/Actions/Actions";
import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import GrowthPopUp from "../../../components/AddGrowthPopup";
import LineGraph from "./LineGraph";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";

export default function GrowthMainPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("AuthToken").replace(" ", "+");
  const UserType = queryParameters.get("UserType");
  const UserId = parseInt(queryParameters.get("UserId"));
  const Dispatch = useDispatch();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [refreshPage, setRefreshData] = useState(false);
  const [isHead, setIsHead] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [data, setData] = useState([]);

  const [graphData, setGraphData] = useState();

  const [weightLetest, setWeightLetest] = useState("0");
  const [heightLetest, setHeightLetest] = useState("0");
  const [headCircLetest, setHeadCirLetest] = useState("0");

  const [lastUpdate, setLastUpdate] = useState("");

  const handleTabSelection = (index) => {
    setSelectedTab(index);
  };
  useEffect(()=>{
    if(!data.length>0){
      let data = {
        name: "",
        min: 0,
        middle: 0,
        middle2: 0,
        max: 0,
        graph: 0,
      };
      setGraphData(data)
    }
  },[data])
  useLayoutEffect(() => {
    if (AuthToken != null) {
      let data = {
        AuthToken,
        UserId,
      };
      Dispatch(setMileStone(data));
      let header = { ...normalHeader, AuthToken, userId: UserId };
      let param = "W";
      if (selectedTab == 2) {
        param = "H";
      }
      if (selectedTab == 3) {
        param = "HC";
      }
      GetGrowthTracker(UserId, param, UserType, header).then((res) => {
        if(res.data!=null){
          if (res.data.length > 0) {
            setData(res.data);
            setIsHead(res.isHead);
          } else {
            setData([]);
          }
        }
       
      });
    }
  }, [selectedTab, refreshPage]);

  useEffect(() => {
    (async () => {
      await GetGrowthTracker(UserId, "W").then((res) => {
        if (res.data.length > 0) {
          const latestDate = res.data.reduce((latest, item) => {
            const currentDate = new Date(item.createdDate);
            if (!latest || currentDate > new Date(latest.createdDate)) {
              return item;
            }
            return latest;
          }, null);
          setWeightLetest(latestDate.Weight);
          setLastUpdate(getDate(latestDate.createdDate));
          if (selectedTab == 1) {
            let Range = latestDate.WeightTo - latestDate.WeightFrom;
            let data = {
              name: res.Name,
              min: (parseFloat(latestDate.WeightFrom) - Range).toFixed(1),
              middle: latestDate.WeightFrom,
              middle2: latestDate.WeightTo,
              max: (parseFloat(latestDate.WeightTo) + Range).toFixed(1),
              graph: latestDate.Weight,
              isMale:res.Gender=="Male"?true:false
            };
            setGraphData(data);
            setLastUpdate(getDate(latestDate.createdDate));
          }
        }else{
          setWeightLetest("0")
        }
      });
      await GetGrowthTracker(UserId, "H").then((res) => {
        if (res.data.length > 0) {
          const latestDate = res.data.reduce((latest, item) => {
            const currentDate = new Date(item.createdDate);
            if (!latest || currentDate > new Date(latest.createdDate)) {
              return item;
            }
            return latest;
          }, null);
          setHeightLetest(latestDate.Height);
          if (selectedTab == 2) {
            let Range = latestDate.HeightTo - latestDate.HeightFrom;
            let data = {
              name: res.Name,
              min: (parseFloat(latestDate.HeightFrom) - Range).toFixed(1),
              middle: latestDate.HeightFrom,
              middle2: latestDate.HeightTo,
              max: (parseFloat(latestDate.HeightTo) + Range).toFixed(1),
              graph: latestDate.Height,
              isMale:res.Gender=="Male"?true:false
            };
            setGraphData(data);
            setLastUpdate(getDate(latestDate.createdDate));
          }
        }else{
          setHeightLetest("0")
        }
      });
      await GetGrowthTracker(UserId, "HC").then((res) => {
        if (res.data.length > 0) {
          const latestDate = res.data.reduce((latest, item) => {
            const currentDate = new Date(item.createdDate);
            if (!latest || currentDate > new Date(latest.createdDate)) {
              return item;
            }
            return latest;
          }, null);
          setHeadCirLetest(latestDate.HeadCircum);
          if (selectedTab == 3) {
            let Range = latestDate.HeadCircumTo - latestDate.HeadCircumFrom;
            let data = {
              name: res.Name,
              min: (parseFloat(latestDate.HeadCircumFrom) - Range).toFixed(1),
              middle: latestDate.HeadCircumFrom,
              middle2: latestDate.HeadCircumTo,
              max: (parseFloat(latestDate.HeadCircumTo) + Range).toFixed(1),
              graph: latestDate.HeadCircum,
              isMale:res.Gender=="Male"?true:false
            };
            setGraphData(data);
            setLastUpdate(getDate(latestDate.createdDate));
          }
        }else{
          setHeadCirLetest("0")
        }
      });
    })();
  }, [selectedTab, refreshPage]);

  const getDate = (date) => {
    if (date == null || date == "") return "N/A";
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM, YYYY");
  };

  const handleAddValueClick = () => {
    if (UserType != "Guest") {
      setPopupOpen(true);
    } else {
      toast.warn("Register with us for tracking growth of your child");
    }
  };
  const handleDeleteValue = (data) => {
    const { NotesCaregiverID } = data;
    if (UserType != "Guest") {
      let header = {
        ...normalHeader,
        AuthToken,
        userId: UserId,
      };
      let d = {
        NotesCaregiverID,
      };
      DeleteGrowthData(d, header).then((res) => {
        if (res.status) {
          setRefreshData((pre) => !pre);
        }
      });
    } else {
      toast.warn("Register with us for tracking growth of your child");
    }
  };
  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light overflow-scroll">
      <GrowthPopUp
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen((pre) => !pre)}
        data={selectedTab}
        AuthToken={AuthToken}
        userId={UserId}
        setRefreshData={setRefreshData}
      />
      <div className="p-4">
        <SliderComponent type={"Assessment"} />
      </div>
      <div className="w-full px-4 py-2 flex items-center justify-between">
        <div
          onClick={() => handleTabSelection(1)}
          className={`w-32 h-12 border px-4  border-black rounded-lg  flex flex-col  items-center justify-center cursor-pointer ${
            selectedTab == 1
              ? "text-white bg-orange-v2"
              : "bg-white text-thtext"
          }`}
        >
          {/* <h2 className="text-sm">{data.age}</h2> */}
          <h2 className="text-sm text-center">Weight ({weightLetest})kg</h2>
        </div>
        <div
          onClick={() => handleTabSelection(2)}
          className={`w-32 h-12 border px-4  border-black rounded-lg  flex flex-col  items-center justify-center cursor-pointer ${
            selectedTab == 2
              ? "text-white bg-orange-v2"
              : "bg-white text-thtext"
          }`}
        >
          {/* <h2 className="text-sm">{data.age}</h2> */}
          <h2 className="text-sm text-center">Height ({heightLetest})cm</h2>
        </div>
        <div
          onClick={() => handleTabSelection(3)}
          className={`w-32 h-12 border px-4  border-black rounded-lg  flex flex-col  items-center justify-center cursor-pointer ${
            selectedTab == 3
              ? "text-white bg-orange-v2"
              : "bg-white text-thtext"
          }`}
        >
          {/* <h2 className="text-sm">{data.age}</h2> */}
          <h2 className="text-sm text-center">
            Head Circ ({headCircLetest})cm
          </h2>
        </div>
      </div>
      <div className="w-full h-48 px-4 py-2 bg-blue-dark flex items-center justify-center">
        <LineGraph {...graphData} />
      </div>
      <div className="flex items-center justify-between px-4 py-2">
        {selectedTab == 3 ? (
          isHead && (
            <button
              onClick={handleAddValueClick}
              className="px-8 py-2 bg-orange-v2 rounded-full text-white"
            >
              Add Value
            </button>
          )
        ) : (
          <button
            onClick={handleAddValueClick}
            className="px-8 py-2 bg-orange-v2 rounded-full text-white"
          >
            Add Value
          </button>
        )}
        {data.length > 0 && (
          <div className="text-white flex items-center space-x-2">
            <AiOutlineClockCircle size={20} />
            <h4>Last updated {lastUpdate}</h4>
          </div>
        )}
      </div>
      <div className=" w-full px-4 py-2">
        <h3 className="text-white text-lg">Growth Measurements</h3>
        <div className="w-full">
          <div className="w-full">
            <table className="w-full my-1 text-sm">
              <thead className="w-full bg-blue-dark h-9 text-white">
                <tr>
                  <th>Date</th>
                  <th>Age</th>
                  {selectedTab == 1 ? (
                    <th>Weight</th>
                  ) : selectedTab == 2 ? (
                    <th>Height</th>
                  ) : (
                    <th>Head circ</th>
                  )}
                  <th>WHO Range</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="w-full">
                {data.map((data, index) => (
                  <tr
                    key={index}
                    className={`w-full bg-blue-300 h-9 ${
                      index % 2 !== 0 ? "bg-blue-50" : "bg-gray-200"
                    }`}
                  >
                    <td>
                      <div className="w-full flex items-center justify-center">
                        <h4>{getDate(data.createdDate)}</h4>
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex items-center justify-center">
                        <h4>{data.age != null ? data.age : "Birth"}</h4>
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex items-center justify-center cursor-pointer">
                        {selectedTab == 1 ? (
                          <h4>{data.Weight}kg</h4>
                        ) : selectedTab == 2 ? (
                          <h4>{data.Height}cm</h4>
                        ) : (
                          <h4>{data.HeadCircum}cm</h4>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="w-full flex items-center justify-center">
                        {selectedTab == 1 ? (
                          <h4>
                            {data.WeightFrom}kg to {data.WeightTo}kg
                          </h4>
                        ) : selectedTab == 2 ? (
                          <h4>
                            {data.HeightFrom}cm to {data.HeightTo}cm
                          </h4>
                        ) : (
                          <h4>
                            {data.HeadCircumFrom}cm to {data.HeadCircumTo}cm
                          </h4>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="w-full h-8 flex items-center justify-center">
                        {parseInt(data.isDelete) == 1 && (
                          <MdOutlineDelete
                            className="text-gray-500  w-6 h-6 cursor-pointer"
                            onClick={() => handleDeleteValue(data)}
                          />
                        )}
                        {/* <MdOutlineDelete className="text-gray-500  w-6 h-6 cursor-pointer" /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!data.length > 0 && (
              <div className="flex w-full py-2 text-lg items-center justify-center text-white">
                <h4>No data</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
