// components/ChildHelpPage.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  HandlePostBasic,
  HandlePostGeust,
  HandlePostUserRelation,
} from "../../utils/Api";
import { setBasicData, setUserData } from "../../Redux/Actions/Actions";
import { toast } from "react-toastify";
import { useLayoutEffect } from "react";

const ChildHelpPage = () => {
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.User);
  const Basic = useSelector((state) => state.Basic);
  const Dispatch = useDispatch();
  const [qs, setQs] = useState("");

  const questions = [
    "Underweight / Overweight",
    "Cold / Cough",
    "Fever",
    "Diarrhea / Vomiting",
    "Ear pain / Infection",
    "Height Growth / Development",
    "Eye irritation",
    "Allergies / Rashes",
    "Chronic Condition",
    "Vaccination / Immunization",
    "Mental Health",
    "Other",
  ];

  const [selectedOptions, setSelectedOptions] = useState(new Array());

  useLayoutEffect(()=>{
    if(UserData.qs!=null){
      setQs(UserData.qs)
    }
    if(Basic.qs!=null){
      setQs(Basic.qs)
    }
  },[])


  const handleOptionChange = (option) => {
    let temp = [questions.length];
    setQs(questions[option]);
    if (selectedOptions.length > 0) {
      for (let i = 0; i < selectedOptions.length; i++) {
        const element = selectedOptions[i];
        if (element != option) {
          temp[option] = option;
        }
      }
      setSelectedOptions(temp);
    } else {
      temp[option] = option;
      setSelectedOptions(temp);
    }
  };

  const handleContinue = () => {

    if(qs==""){
      toast.warning("Please select a question")
      return
    }
    let bas = Basic;
    bas.qs = qs;
    Dispatch(setBasicData(bas));
    navigate("/doctor-cons");
    
    // if (Basic.isExisted) {
     
    // } 
    
    // else {
    //   let temp = UserData;
    //   temp.helpQuestion1 = qs;
    //   if (temp.Relation == null) {
    //     let headers = {
    //       "Content-Type": "application/json",
    //       device: "Android",
    //       Accept: "application/json",
    //       CorrelationId: 1234551516516565,
    //       AuthToken: Basic.authToken,
    //       userId: Basic.id,
    //     };
    //     temp.Relation = "self";
    //     temp.ConnectionID = temp.guestId;
    //     HandlePostBasic(headers,temp).then((res) =>
    //       res.json().then((d) => {
    //         if(d.status==true){
    //           console.log(d);
    //           let bas = Basic;
    //           bas.qs = qs;
    //           Dispatch(setBasicData(bas));
    //           navigate("/doctor-cons");
    //         }else{
    //           console.log(d);
    //           toast.warn("User not found")
    //         }
           
    //       })
    //     );
    //   } else {
    //     temp.ConnectionID = temp.guestId;
    //     temp.mobileNumber = Basic.mobileNumber;
    //     temp.mobileCode = Basic.code;
    //     if ((Basic.type = "Patient")) {
    //       let headers = {
    //         "Content-Type": "application/json",
    //         device: "Android",
    //         Accept: "application/json",
    //         CorrelationId: 1234551516516565,
    //         AuthToken: Basic.authToken,
    //         userId: Basic.id,
    //       };
    //       HandlePostUserRelation(headers, temp).then((res) =>
    //         res.json().then((d) => {
    //           let bas = Basic;
    //           bas.qs = qs;
    //           bas.id=d.userID;
    //           bas.type=d.type;
    //           bas.authToken=d.authToken;
    //           let temp =  UserData;
    //           temp.Relation=null;
    //           Dispatch(setUserData(temp))
    //           Dispatch(setBasicData(bas));
    //           // toast.success("Relation added.")
    //           navigate("/doctor-cons");
    //         })
    //       );
    //     } else {
    //       HandlePostGeust(temp).then((res) =>
    //         res.json().then((d) => {
    //           let bas = Basic;
    //           bas.qs = qs;
    //           bas.id=d.userID;
    //           bas.type=d.type;
    //           bas.authToken=d.authToken;
    //           let temp =  UserData;
    //           temp.Relation=null;
    //           Dispatch(setUserData(temp))
    //           Dispatch(setBasicData(bas));
    //           // toast.success("Relation added.")
    //           navigate("/doctor-cons");
    //         })
    //       );
    //     }
    //   }
    // }

    //
  };

  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      <h1 className="text-2xl font-bold mb-4">How can we help your child?</h1>
      <p>Answer question(s) about your child and our doctor will call in 5 minutes</p>
      <div className="h-3/4 flex flex-col justify-between">
        <div className="mt-8 grid grid-cols-2 gap-1 overflow-scroll">
          {questions.map((question, index) => (
            <div
              key={index}
              className="mb-4 p-4 flex items-center rounded-xl bg-gray-100 border border-gray-200 w-36 h-14"
              // onClick={}
            >
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={`question_${index}`}
                  value={index}
                  checked={question ==qs}
                  onChange={() => handleOptionChange(index)}
                  className=" appearance-none h-6 w-6 border-2 border-primary rounded-lg bg-transparent
                mt-1 shrink-0
                checked:bg-primary checked:border-0"
                />
              </label>
              <p className="ml-2 text-xs font-semibold">{question}</p>
            </div>
          ))}
        </div>
        <div className="flex">
          <button
            className=" bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChildHelpPage;
