import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const WarningPopup = ({ isOpen, onClose, message }) => {
  const key = "fdgjdfjfkhkgfk";
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? "" : "hidden"}`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0  flex items-center justify-center">
        <AnimatePresence>
          <motion.div
            className="fixed bottom-0 max-w-md w-full  bg-gray-300 p-6 rounded-t-lg "
            key={key}
            initial={{ y: "100%" }}
            animate={{ y: isOpen ? 0 : "100%" }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
          >
            <div className="flex flex-col justify-center">
              <div className="flex w-full items-center justify-center font-bold text-xl h-8 mb-4">
                <h3>Warning</h3>
              </div>
              <div className="w-full py-4 flex items-center justify-center">
                <h1>{message}</h1>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  className=" rounded-full text-white bg-blue-dark w-full h-10"
                  onClick={() => onClose(false)}
                >
                  Cancel
                </button>
                <button
                  className=" rounded-full text-white bg-blue-dark w-full h-10"
                  onClick={() => onClose(true)}
                >
                  Ok
                </button>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default WarningPopup;
