import { useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart, GetPaymentInfo, GetPlanById, UpdateCartStatus } from "../utils/Api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../Redux/Actions/Actions";
import { toast } from "react-toastify";

// cgst: 9;
// discount: 5;
// igst: 0;
// isKitAvailable: false;
// message: "ItemDetails";
// planCostAnnual: 12000;
// planCostMonthly: 1000;
// planDesc: "Care Team\r\n24/7 Access to Paediatrician\r\nDedicated Care Officer\r\nCare Services\r\nGrowth Monitoring\r\nVaccination Monitoring\r\nNutrition and Dietary Advice\r\nElectronic Health Records\r\nPersonalized Care Plan\r\nMonitoring Kit\r\nOtoscope\r\nThermometer\r\nOximeter\r\nOn Demand Services\r\nLab Test(Sample Collection) at home\r\nMedicine Delivery\r\n24/7 Ambulance Service";
// planDuration: 12;
// planId: 4;
// planName: "Short Plan";
// sgst: 9;
// status: true;
// statusCode: 200;
// totalItems: 1;

export default function PayementPage() {
  const navigate = useNavigate();
  const BasicData = useSelector((state) => state.Basic);
  const [payement, setPayment] = useState("");
  const [withGst, setWithGst] = useState("");
  const [total, setTotal] = useState("");
  const [paybtnname, setPayBtnName] = useState("Pay now");
  const [UID, setUID] = useState("");
  const [timer, setTimer] = useState(10000);
  const [isOnGateway, setIsOnGateWay] = useState(false);
  const Dispatch = useDispatch();
  const normalHeader={
    'Content-Type':'application/json',
    device:"Android",
    Accept:"application/json",
    CorrelationId:1234551516516565,
    AuthToken:BasicData.authToken,
    userId:BasicData.id
}
  useLayoutEffect(() => {
    GetPlanById(normalHeader,14, BasicData.type, BasicData.id).then((d) => {
      console.log(d);
      setPayment(d);
      setTotal(d.planCostAnnualWithGST)
      if(d.planCostAnnualWithGST==0){
        setPayBtnName("Proceed")
      }
      // let gst = d.cgst + d.sgst ;
      // let discount = d.planCostAnnual - d.discount;
      // let withGst = (gst / 100) * discount;
      // // let withGst = gst;
      // withGst = Math.floor(withGst * 100) / 100
      // setWithGst(withGst.toFixed(2));
      // handleTotal(discount, withGst);
    });
  }, []);
  const handlepayment = () => {
    if (payement.status == false) {
      toast.error("Some error please re-try");
      navigate("/");
      return;
    }
    let data = {
      userType: BasicData.type,
      userId: BasicData.id,
      orders: [
        {
          PlanId: payement.planId,
          PlanName: payement.planName,
          PlanDesc: payement.planDesc,
          PlanDuration: payement.planDuration,
          Amount: payement.planCostAnnual,
          Disc: payement.discount,
          Total: total,
          ServiceType: "CONSULTATION",
          UserId: BasicData.id,
          UserType: BasicData.type,
          ServiceId: BasicData.appReqId,
          DoctorId: -1,
          orderDetails: [
            {
              PlanId: payement.planId,
              ItemId: 1,
              PlanName: payement.planName,
              PlanDesc: payement.planDesc,
              Tariff: payement.planCostAnnual,
              GST: withGst,
              Disc1: payement.discount,
              Amount: total,
            },
          ],
        },
      ],
    };
    AddToCart(normalHeader,data).then((res) => {
      setIsOnGateWay(true);
      setTimer(timer - 1);
      setUID(res.uniqueId);
      if (res.status) {
        // navigate("/doctor-confirmed");
        // return
        if(payement.planCostAnnualWithGST==0){
          let data ={
            UserType:BasicData.type,
            UserId:BasicData.id,
            UniqueId:res.uniqueId,
            Status:"Success",
            TransactionId:""
          }
          UpdateCartStatus(normalHeader,data).then(d=>{
            if(d.status==true){
              navigate("/doctor-confirmed");
            }
          })
        }else{
          Dispatch(setLoading({ loading: true, otp: false }));
          let url = `https://mygleeo.com/payment/index.php?mobile=${BasicData.mobileNumber}&pid=${payement.planId}&uniqueid=${res.uniqueId}&userId=${BasicData.id}&type=${BasicData.type}&authtoken=${BasicData.authToken}`;
          window.open(url);
          try {
            window.Android.showToast(JSON.stringify({
                type: "PAYMENT_WINDOW_OPEN",
                isClosed: false,
                url
            }))
        } catch (error) {
            console.log(error);
        }
        }
      
      }
    });
  };
  const handleTotal = (discount, withgst) => {
    setTotal(discount + withgst);
  };
  window.onbeforeunload = function () {
    return "Data will be lost if you leave the page, are you sure?";
  };
  useEffect(() => {
    if (timer <= 0) {
      toast.warn("Some error please retry");
      Dispatch(setLoading({ loading: false, otp: false }));
      navigate("/details");
      return;
    }
    const timeoutFunction = setInterval(handlePayementResponse, 1000);
    return () => clearInterval(timeoutFunction);
  }, [timer]);

  const handlePayementResponse = () => {
    if (!isOnGateway) return;
    let data = {
      userType: BasicData.type,
      userId: BasicData.id,
      uniqueId: UID,
    };
    GetPaymentInfo(normalHeader,data).then((res) => {
      if (res.cartStatus != "1") {
        if (res.cartStatus == "Aborted") {
          Dispatch(setLoading({ loading: false, otp: false }));
          toast.warn("Payment Aborted.");
          navigate("/details");
          return;
        }
        if (res.cartStatus == "Success") {
          Dispatch(setLoading({ loading: false, otp: false }));
          toast.warn("Payment Success.");
          navigate("/doctor-confirmed");
          return;
        }
        if (res.cartStatus == "Failure") {
          Dispatch(setLoading({ loading: false, otp: false }));
          toast.warn("Payment Failed.");
          navigate("/details");
          return;
        }
        Dispatch(setLoading({ loading: false, otp: false }));
        toast.warn("Payment Failed.");
        navigate("/details");
      }
    });
    setTimer(timer - 1);
  };
  return (
    <div className="container h-screen max-w-md mx-auto p-8 text-thtext">
      <div className="h-3/4 flex flex-col justify-between">
        <div className="bg-gray-300 rounded-xl border border-gray-400 flex flex-col p-6 text-thtext text-base">
          <div className="flex items-center justify-between  border-gray-400 pb-4">
            <h1>Consultation service</h1>
            <h1>₹ {payement.planCostAnnualWithGST}</h1>
          </div>
          {/* <div className="flex items-center justify-between mt-4 font-bold">
            <h1>Discount</h1>
            <h1>₹ {payement.discount}</h1>
          </div>
          <div className="flex items-center justify-between mt-4 border-b border-gray-400 pb-4">
            <h1>GST</h1>
            <h1>₹ {withGst}</h1>
          </div> */}
          <div className="flex items-center justify-between mt-4 text-lg font-bold">
            <h1>Total</h1>
            <h1>₹ {total}</h1>
          </div>
        </div>
        <div className="flex">
          <button
            className=" bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-primary h-12 text-white font-bold py-2 px-4 rounded-full w-full mt-8 ml-2"
            onClick={handlepayment}
          >
            {paybtnname}
          </button>
        </div>
      </div>
    </div>
  );
}
