import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import SliderComponent from "../SliderComponent";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiOutlineTrophy, AiOutlineFilter } from "react-icons/ai";
import {
  GetMileStoneData,
  PostMileStoneStatus,
  normalHeader,
} from "../../../utils/Api";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setMileStone } from "../../../Redux/Actions/Actions";
import { useNavigate } from "react-router-dom";
const ages = [
  {
    FromMonth: 0,
    ToMonth: 2,
    age: "0-2",
    type: "Months",
  },
  { FromMonth: 2, ToMonth: 4, age: "2-4", type: "Months" },
  {
    FromMonth: 4,
    ToMonth: 6,
    age: "4-6",
    type: "Months",
  },
  {
    FromMonth: 6,
    ToMonth: 9,
    age: "6-9",
    type: "Months",
  },
  {
    FromMonth: 9,
    ToMonth: 12,
    age: "9-12",
    type: "Months",
  },
  {
    FromMonth: 12,
    ToMonth: 15,
    age: "12-15",
    type: "Months",
  },
  {
    FromMonth: 15,
    ToMonth: 18,
    age: "15-18",
    type: "Months",
  },
  {
    FromMonth: 18,
    ToMonth: 24,
    age: "18-24",
    type: "Months",
  },
  {
    FromMonth: 24,
    ToMonth: 30,
    age: "24-30",
    type: "Months",
  },
  {
    FromMonth: 30,
    ToMonth: 36,
    age: "30-36",
    type: "Months",
  },
  {
    FromMonth: 36,
    ToMonth: 48,
    age: "3-4",
    type: "Years",
  },
  {
    FromMonth: 48,
    ToMonth: 60,
    age: "4-5",
    type: "Years",
  },
  {
    FromMonth: 60,
    ToMonth: 72,
    age: "5-6",
    type: "Years",
  },
  {
    FromMonth: 72,
    ToMonth: 84,
    age: "6-7",
    type: "Years",
  },
  {
    FromMonth: 84,
    ToMonth: 96,
    age: "7-8",
    type: "Years",
  },
];
const ageImages = [
  {
    age: "0-2",
    src: "/assets/milestone/Frame 197.svg",
  },
  {
    age: "2-4",
    src: "/assets/milestone/Frame 198.svg",
  },
  {
    age: "4-6",
    src: "/assets/milestone/Frame 199.svg",
  },
  {
    age: "6-9",
    src: "/assets/milestone/Frame 200.svg",
  },
  {
    age: "9-12",
    src: "/assets/milestone/Frame 201.svg",
  },
  {
    age: "10-12",
    src: "/assets/milestone/Frame 202.svg",
  },
  {
    age: "12-15",
    src: "/assets/milestone/Frame 203.svg",
  },
  {
    age: "15-18",
    src: "/assets/milestone/Frame 204.svg",
  },
  {
    age: "18-24",
    src: "/assets/milestone/Frame 205.svg",
  },
  {
    age: "24-30",
    src: "/assets/milestone/Frame 206.svg",
  },
  {
    age: "30-36",
    src: "/assets/milestone/Frame 207.svg",
  },
  {
    FromMonth: 36,
    ToMonth: 48,
    age: "3-4",
    type: "Years",
    src: "/assets/milestone/Age4.svg",
  },
  {
    FromMonth: 48,
    ToMonth: 60,
    age: "4-5",
    type: "Years",
    src: "/assets/milestone/Age5.svg",
  },
  {
    FromMonth: 60,
    ToMonth: 72,
    age: "5-6",
    type: "Years",
    src: "/assets/milestone/Age6.svg",
  },
  {
    FromMonth: 72,
    ToMonth: 84,
    age: "6-7",
    type: "Years",
    src: "/assets/milestone/Age7.svg",
  },
  {
    FromMonth: 84,
    ToMonth: 96,
    age: "7-8",
    type: "Years",
    src: "/assets/milestone/Age8.svg",
  },
];

export default function Milestone() {
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("AuthToken").replace(" ", "+");
  const UserType = queryParameters.get("UserType");
  const UserId = parseInt(queryParameters.get("UserId"));
  const PlanId = parseInt(queryParameters.get("planId"));
  const MileStoneData = useSelector((state) => state.Milestone);

  const Dispatch = useDispatch();
  const [milestoneFilter, setMileStoneFlter] = useState("all");
  const [isAgeSelected, setAgeSelected] = useState(0);
  const [SProgressbar, setSprogresbar] = useState(0);
  const [LProgressbar, setLprogresbar] = useState(0);
  const [CProgressbar, setCprogresbar] = useState(0);
  const [MCProgressbar, setMprogresbar] = useState(0);
  const [SText, setSText] = useState("0/0");
  const [LText, setLText] = useState("0/0");
  const [CText, setCText] = useState("0/0");
  const [MText, setMText] = useState("0/0");
  const [selectedAge, setSelectedAge] = useState();
  const [dataMain, setDataMain] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [dataL, setDataL] = useState([]);
  const [dataC, setDataC] = useState([]);
  const [dataM, setDataM] = useState([]);
  const [dataSF, setDataSF] = useState([]);
  const [dataLF, setDataLF] = useState([]);
  const [dataCF, setDataCF] = useState([]);
  const [dataMF, setDataMF] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [isFirstLoadDone, setFirstLoad] = useState(false);
  const scrollableDivRef = useRef(null);

  useLayoutEffect(() => {
    Dispatch(setLoading({ loading: true, otp: false }));
    setSelectedAge(ages[0]);
    if (AuthToken != null) {
      let data = {
        AuthToken,
        UserId,
      };
      Dispatch(setMileStone(data));
    }
  }, []);

  useLayoutEffect(() => {
    if (selectedAge != null) {
      let header = { ...normalHeader, AuthToken, userId: UserId };
      GetMileStoneData(
        UserId,
        selectedAge.FromMonth,
        selectedAge.ToMonth,
        PlanId,
        UserType,
        header
      ).then((res) => {
        if (res.status == true) {
          setDataMain(res.data);
          if (!isFirstLoadDone) {
            let ageMonth = res.agemonth;
            ages.forEach((data, index) => {
              if (ageMonth >= data.FromMonth && ageMonth <= data.ToMonth) {
                setAgeSelected(index);
                setSelectedAge((pre) => data);
                scrollToElement(index);
              }
            });
            setFirstLoad(true);
          }
          const {
            MTotcount,
            MAchcount,
            STotcount,
            SAchcount,
            LTotcount,
            LAchcount,
            CTotcount,
            CAchcount,
          } = res;
          setMText(`${MAchcount}/${MTotcount}`);
          setSText(`${SAchcount}/${STotcount}`);
          setLText(`${LAchcount}/${LTotcount}`);
          setCText(`${CAchcount}/${CTotcount}`);

          let m = (100 * MAchcount) / MTotcount;
          let s = (100 * SAchcount) / STotcount;
          let l = (100 * LAchcount) / LTotcount;
          let c = (100 * CAchcount) / CTotcount;

          setMprogresbar(m);
          setSprogresbar(s);
          setLprogresbar(l);
          setCprogresbar(c);
          Dispatch(setLoading({ loading: false, otp: false }));
        }
      });
    }
  }, [selectedAge, refresh]);

  useEffect(() => {
    if (dataMain.length > 0) {
      if (milestoneFilter == "all") {
        filterDataByCate(dataMain);
      }
      if (milestoneFilter == "overdue") {
        let data = dataMain.filter((data) => data.Goalstatus === "O");
        if (data.length > 0) {
          filterDataByCate(data);
        } else {
          filterDataByCate([""]);
        }
      }
      if (milestoneFilter == "upcoming") {
        let data = dataMain.filter((data) => data.Goalstatus === "U");
        if (data.length > 0) {
          filterDataByCate(data);
        } else {
          filterDataByCate([""]);
        }
      }
      if (milestoneFilter == "achived") {
        let data = dataMain.filter((data) => data.Goalstatus === "D");
        if (data.length > 0) {
          filterDataByCate(data);
        } else {
          filterDataByCate([""]);
        }
      }
    }
  }, [dataMain, milestoneFilter]);

  useEffect(() => {
    if (dataS.length > 0) {
      let filtered = dataS.filter(
        (data) =>
          data.FromMonth == selectedAge.FromMonth &&
          data.ToMonth == selectedAge.ToMonth
      );
      setDataSF(filtered);
    } else {
      setDataSF([]);
    }
    if (dataM.length > 0) {
      let filtered = dataM.filter(
        (data) =>
          data.FromMonth == selectedAge.FromMonth &&
          data.ToMonth == selectedAge.ToMonth
      );
      setDataMF(filtered);
    } else {
      setDataMF([]);
    }
    if (dataC.length > 0) {
      let filtered = dataC.filter(
        (data) =>
          data.FromMonth == selectedAge.FromMonth &&
          data.ToMonth == selectedAge.ToMonth
      );
      setDataCF(filtered);
    } else {
      setDataCF([]);
    }
    if (dataL.length > 0) {
      let filtered = dataL.filter(
        (data) =>
          data.FromMonth == selectedAge.FromMonth &&
          data.ToMonth == selectedAge.ToMonth
      );
      setDataLF(filtered);
    } else {
      setDataLF([]);
    }
  }, [selectedAge, dataS, dataM, dataC, dataL]);

  const handleAgeClick = (data, index) => {
    setAgeSelected(index);
    setSelectedAge(data);
    Dispatch(setLoading({ loading: true, otp: false }));
  };

  const getImageByAge = (age) => {
    if (age != null) {
      const data = ageImages.filter((data) => data.age == age);
      return data[0].src;
    }
    return "";
  };

  const filterDataByCate = (data) => {
    if (data.length > 0) {
      // Social/Emotional, Movement, Cognitive,Language
      setDataS([]);
      setDataM([]);
      setDataC([]);
      setDataL([]);

      let socialdata = data.filter(
        (data) => data.GoalCategory == "Social/Emotional"
      );
      let movementData = data.filter((data) => data.GoalCategory == "Movement");
      let cognitiveData = data.filter(
        (data) => data.GoalCategory == "Cognitive"
      );
      let languageData = data.filter((data) => data.GoalCategory == "Language");
      setDataS(socialdata);
      setDataM(movementData);
      setDataC(cognitiveData);
      setDataL(languageData);
    }
  };
  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      // const position = targetElement.offsetLeft - 432;
      // scrollableDivRef.current.scrollLeft = position;
    }
  };
  return (
    <div className="container h-screen max-w-md mx-auto text-thtext bg-blue-light overflow-scroll">
      <div className="flex items-center justify-between px-4 mt-2">
        <div></div>
        <div className="flex items-center space-x-2">
          <select
            className=" bg-transparent outline-none text-blue-dark"
            value={milestoneFilter}
            onChange={(e) => setMileStoneFlter(e.target.value)}
          >
            <option value={"overdue"}>Overdue Milestones</option>
            <option value={"upcoming"}>Upcoming Milestones</option>
            <option value={"achived"}>Achieved Milestones</option>
            <option value={"all"}>Show All Milestones</option>
          </select>
          <AiOutlineFilter size={25} className=" text-blue-dark" />
        </div>
      </div>
      <div className="p-4 w-full">
        <SliderComponent type={"Milestones"} />
      </div>
      <div
        className="w-full px-4 py-2 flex items-center space-x-2 overflow-scroll overflow-y-hidden"
        ref={scrollableDivRef}
      >
        {ages.map((data, index) => (
          <div
            id={index}
            onClick={() => handleAgeClick(data, index)}
            key={index}
            className={`w-24 h-12 border px-4  border-black rounded-lg  flex flex-col  items-center justify-center ${
              isAgeSelected == index
                ? "text-white bg-orange-v2"
                : "bg-white text-thtext"
            }`}
          >
            <h2 className="text-sm">{data.age}</h2>
            <h2 className="text-sm">{data.type}</h2>
          </div>
        ))}
      </div>
      {dataMain.length > 0 ? (
        <div>
          <div className="w-full px-4 py-2 grid grid-cols-2 gap-2 bg-blue-dark">
            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-white text-xl">S</h3>
                <div className=" w-14 h-14">
                  <CircularProgressbar
                    value={SProgressbar}
                    text={SText}
                    styles={buildStyles({
                      rotation: 0,
                      // strokeLinecap: "butt",
                      textSize: "30px",
                      pathTransitionDuration: 0.5,
                      pathColor: `#38ba49`,
                      textColor: "#ffffff",
                      trailColor: "#ffffff",
                      // backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-white text-xl">L</h3>
                <div className=" w-14 h-14">
                  <CircularProgressbar
                    value={LProgressbar}
                    text={LText}
                    styles={buildStyles({
                      rotation: 0,
                      // strokeLinecap: "butt",
                      textSize: "30px",
                      pathTransitionDuration: 0.5,
                      pathColor: `#38ba49`,
                      textColor: "#ffffff",
                      trailColor: "#ffffff",
                      // backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className=" w-14 h-14">
                  <CircularProgressbar
                    value={CProgressbar}
                    text={CText}
                    styles={buildStyles({
                      rotation: 0,
                      // strokeLinecap: "butt",
                      textSize: "30px",
                      pathTransitionDuration: 0.5,
                      pathColor: `#38ba49`,
                      textColor: "#ffffff",
                      trailColor: "#ffffff",
                      // backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
                <h3 className="text-white text-xl">C</h3>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className=" w-14 h-14">
                  <CircularProgressbar
                    value={MCProgressbar}
                    text={MText}
                    styles={buildStyles({
                      rotation: 0,
                      // strokeLinecap: "butt",
                      textSize: "30px",
                      pathTransitionDuration: 0.5,
                      pathColor: `#38ba49`,
                      textColor: "#ffffff",
                      trailColor: "#ffffff",
                      // backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
                <h3 className="text-white text-xl">M</h3>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center justify-center">
                <h2 className="text-2xl text-white">
                  {selectedAge?.age} {selectedAge?.type}
                </h2>
                <img
                  className="mt-2 w-28 h-28"
                  alt="age-image"
                  src={getImageByAge(selectedAge?.age)}
                />
                <div className="flex items-center justify-between w-full mt-2">
                  <div className="flex items-center space-x-1">
                    <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                    <h2 className="text-xs text-white">Overdue</h2>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
                    <h2 className="text-xs text-white">Upcoming</h2>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                    <h2 className="text-xs text-white">Done</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 py-2">
            {dataSF.length > 0 && (
              <MileStoneListComponent
                data={dataSF}
                name={"Social/Emotional"}
                onRefresh={() => setRefresh((pre) => !pre)}
              />
            )}
            {dataMF.length > 0 && (
              <MileStoneListComponent
                data={dataMF}
                name={"Movement"}
                color={1}
                onRefresh={() => setRefresh((pre) => !pre)}
              />
            )}
            {dataCF.length > 0 && (
              <MileStoneListComponent
                data={dataCF}
                name={"Cognitive"}
                color={2}
                onRefresh={() => setRefresh((pre) => !pre)}
              />
            )}
            {dataLF.length > 0 && (
              <MileStoneListComponent
                data={dataLF}
                name={"Language"}
                onRefresh={() => setRefresh((pre) => !pre)}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full my-10 flex items-center justify-center">
          <h4 className="">No data</h4>
        </div>
      )}
    </div>
  );
}

const MileStoneListComponent = ({ name, data, color, onRefresh }) => {
  return (
    <div className="w-full">
      <div className="mb-1">
        <h3 className="text-white text-xl">{name}</h3>
      </div>
      <div>
        {data.map((data, index) => (
          <MileStoneListViewComponent
            key={index}
            data={data}
            color={color}
            onRefresh={onRefresh}
          />
        ))}
      </div>
    </div>
  );
};

const MileStoneListViewComponent = ({ data, color, onRefresh }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const UserType = queryParameters.get("UserType");
  const [activeBtnIndex, setActiveBtnIndex] = useState(0);
  let MileStoneData = useSelector((state) => state.Milestone);
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (data.Intervention != null) {
      setActiveBtnIndex(data.Intervention);
    }
  }, [data]);

  const handleClick = (status, id) => {
    setActiveBtnIndex(status);
    let header = {
      ...normalHeader,
      AuthToken: MileStoneData.AuthToken,
      userId: MileStoneData.UserId,
    };
    let data = {
      patientId: MileStoneData.UserId,
      type: "Goal",
      webRecordId: id,
      isAgree: status,
      achievedDate: null,
    };
    // console.log(header);
    console.log(data);
    PostMileStoneStatus(data, header).then((res) => {
      if (res.status) {
        onRefresh(true);
        toast.success("Milestone status updated.");
      } else {
        toast.error("Failed to update goal status.");
      }
    });
  };
  const handleFarmClick = (data) => {
    MileStoneData.data = data;
    Dispatch(setMileStone(MileStoneData));
    navigate("form");
  };

  const GetDate = (date) => {
    return moment(date).format("DD-MMM-YYYY");
  };
  const handleToFarmClick = () => {
    if (data.GoalObservationID != null) {
      handleFarmClick(data);
    } else {
      if (UserType == "Guest") {
        
        toast.warning("Register with us for milestone due dates of your child");
      } else {
        toast.warning(
          "You can extend/renew your plan to know the due date of this milestone"
        );
      }
    }
  };
  return (
    <div
      className={`w-full flex rounded-2xl  mb-2 ${
        color == 1 ? "bg-movement" : color == 2 ? "bg-cognitive" : "bg-gray-200"
      }`}
    >
      <div className="p-2">
        <AiOutlineTrophy
          className={`w-8 h-8 ${
            data?.Goalstatus == "O"
              ? "text-red-500"
              : data?.Goalstatus == "U"
              ? "text-yellow-500"
              : data?.Goalstatus == "D"
              ? "text-green-500"
              : ""
          }`}
        />
      </div>
      <div className="px-3 py-2 w-full">
        <div onClick={handleToFarmClick} className=" cursor-pointer">
          <h4 className="text-sm">{data?.GoalName}</h4>
        </div>
        <div className="flex text-xs items-center justify-between">
          {data?.RxDate != "" && <h5>Due on {GetDate(data?.RxDate)}</h5>}
          {data?.AchvdDate != "" && (
            <h5>Achieved on {GetDate(data?.AchvdDate)}</h5>
          )}
        </div>
        {data.GoalObservationID != null && (
          <div className="w-full flex items-center justify-between pt-2">
            <button
              onClick={() => handleClick(-1, data.webRecordId)}
              className={`w-max  text-sm  px-3 py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                activeBtnIndex == -1
                  ? "bg-blue-dark text-white"
                  : " bg-transparent"
              }`}
            >
              Not yet
            </button>
            <button
              onClick={() => handleClick(3, data.webRecordId)}
              className={`w-max px-3  text-sm  py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                activeBtnIndex == 3
                  ? "bg-blue-dark text-white"
                  : " bg-transparent"
              }`}
            >
              Sometime
            </button>
            <button
              onClick={() => handleClick(1, data.webRecordId)}
              className={`w-max px-3 text-sm py-1 flex items-center justify-center rounded-full border border-blue-dark ${
                activeBtnIndex == 1
                  ? "bg-blue-dark text-white"
                  : " bg-transparent"
              }`}
            >
              Frequently
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
